import React from "react";
import { Link } from "react-router-dom";
import HomeIcon from "~/assets/images/components/EndBar/BackHome.svg";

function HomeButton (props)
{
  return (
    <Link to="/index">
      <img
        src={HomeIcon}
        width="15%"
        style={{
          position: "fixed",
          zIndex: 3,
          bottom: props.bottom ?? "25vh",
          left: 0,
          width: "2.5em",
        }}
      />
    </Link>
  );
}

export default HomeButton;
