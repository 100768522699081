import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const contentStyle = {
  background: "#cfcece",
  width: "100%",
  height: "80%",
  borderRadius: "5%",
};

class GameMPayoutPopup extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Popup open={this.props.payouts.length == 0 ? false : true} modal closeOnDocumentClick={false} onClose={this.props.setOpen} nested {...{ contentStyle }}>
        {(close) => (
          <div className="bg-transparent modal modal-content border-0">
            <button className="close" onClick={close}></button>
            <Grid container>
              <Grid item xs={12} textAlign="center">
                <Typography variant="h5">{this.props.i18n.t("m_game.payout_details")}</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "center", border: "1px solid #333" }}>
                <Typography variant="h6">{this.props.i18n.t("m_game.final_rank")}</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "center", border: "1px solid #333" }}>
                <Typography variant="h6">{this.props.i18n.t("m_game.payout")}</Typography>
              </Grid>
              {this.props.payouts.map((payout, index) => {
                return (
                  <React.Fragment key={index}>
                    <Grid item xs={6} style={{ textAlign: "center", border: "1px solid #333" }}>
                      {payout.rank_from == payout.rank_to ? payout.rank_from : `${payout.rank_from} - ${payout.rank_to}`}
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "right", border: "1px solid #333", borderRight: "none" }}>
                      {payout.quantity}
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "left", border: "1px solid #333", borderLeft: "none" }}>
                      <img src={payout.img} style={{ height: "1em" }} />
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </div>
        )}
      </Popup>
    );
  }
}

export default withTranslation()(GameMPayoutPopup);
