import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextField, makeStyles } from "@material-ui/core";
import LoginDesign from "~/assets/images/components/Auth/LoginDesign.svg";
import { toast } from "react-toastify";
// import "react-phone-number-input/style.css";

const useStyles = makeStyles((theme) => ({
  input: {
    color: "#FFF",
    cssUnderline: {
      "&:after": {
        borderBottomColor: "#333333",
      },
      disableUnderline: true,
      underline: {
        "&&&:before": {
          borderBottom: "none",
        },
      },
    },
  },
}));

export default function ForgotPassword() {
  const { t, i18n } = useTranslation();
  const [Email, setEmail] = React.useState("");
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const reset_send = () => {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios.post(`/api/password/forget`, { email: Email })
      .then((response) => {
        document.getElementById("loading_curtain").classList.add("d-none");
        window.Sound_TwinkleAlert.play();
        toast.success(t('forget_password.success_message'), {
          containerId: "Toast_color_flip",
          autoClose: 3000,
        });
      });
  };

  const classes = useStyles();
  return (
    <Grid container spacing={{ xs: 1, md: 1.5 }} direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={10} height="18vh" display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h4" color="white" textAlign="center">
          {t("forget_password.forgot_your_password")}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6} display="flex" justifyContent="center" alignItems="center">
        <img src={LoginDesign} width="50%" />
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={10} lg={6} container justifyContent="center" alignItems="center">
        <TextField
          id="filled-multiline-flexible"
          type="email"
          required
          label={t("forget_password.email")}
          value={Email}
          onChange={handleEmailChange}
          variant="filled"
          size="small"
          fullWidth={true}
          InputLabelProps={{
            className: classes.input,
          }}
          inputProps={{ className: classes.input }}
        />
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={7} lg={3} display="flex" justifyContent="center" alignItems="center">
        <Button onClick={reset_send} variant="outlined" style={{ width: "100%", backgroundColor: "#5C2D80", color: "white", borderColor: "white" }}>
          {t("forget_password.send_email")}
        </Button>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={7} lg={3} display="flex" justifyContent="center" alignItems="center">
        <Button variant="outlined" component={Link} to="/login" style={{ width: "100%", backgroundColor: "#242634", color: "white", borderColor: "white" }}>
          {t("forget_password.back_to_login")}
        </Button>
      </Grid>
    </Grid>
  );
}
