import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Success from "~/assets/images/components/Purchase/Success.svg";

class PaymentSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
      order_id: null,
      order_time: null,
    };
  }

  componentDidMount() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios.get(`/api/order/${this.props.match.params.order_id}`).then((response) => {
      this.setState(response.data);
      document.getElementById("loading_curtain").classList.add("d-none");
    });
  }

  render() {
    if (!this.state.title) {return <></>;}
    return (
      <Grid container direction="row" justifyContent="center" alignContent="center" height="80vh">
        <Grid item xs={10} md={8} lg={6.2} display="flex" flexDirection="column" justifyContent="space-around" alignItems="center">
          <Typography variant="h6" color="white" textAlign="center">
            {this.props.i18n.t("purchase_success.title", { title: this.state.title[`title_${this.props.i18n.language}`] })}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={6.2} display="flex" flexDirection="column" justifyContent="space-around" alignItems="center">
          <Typography variant="subtitle2" color="white" textAlign="center">
            {this.props.i18n.t("purchase_success.description", { order_id: this.state.order_id, order_time: this.state.order_time })}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={6.2} display="flex" justifyContent="center" alignItems="center">
          <img src={Success} width="50%" />
        </Grid>
        <Grid item xs={6.2} display="flex" justifyContent="center" alignItems="center">
          <Button variant="outlined" component={Link} to={window.apiToken ? "/transaction" : "/index"} style={{ width: "100%", backgroundColor: "#5C2D80", color: "white", borderColor: "white" }}>
            {window.apiToken ? this.props.i18n.t("purchase_success.return") : this.props.i18n.t("purchase_success.login")}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(PaymentSuccess);
