import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Card from '@mui/material/Card';
import CardActions from "@mui/material/CardActions";
import CardContent from '@mui/material/CardContent';
import CardMedia from "@mui/material/CardMedia";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";


import { FaRegQuestionCircle, FaUser } from "react-icons/fa";
import Help from "~/components/Game/GameHelpPopup";
import GameRulePopup from "~/components/Game/GameRulePopup";
import ForwardNoShadow from "~/assets/images/components/General/ForwardNoShadow.svg";

class ModeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      HelpPopup: false,
      game_name: false,
      rule_content: false,
      g: [],
      v: [],
      s: [],
      m: [],
      time: Math.floor(new Date()),
    };
    this.handleLink = this.handleLink.bind(this);
    this.helpState = this.helpState.bind(this);
    this.ruleState = this.ruleState.bind(this);
  }
  componentDidMount() {
    this.refresh_slider();
  }

  tick() {
    this.setState({ time: Math.floor(new Date()) });
  }

  handleLink(link) {
    window.Sound_NewSuccess.play();
    this.props.history.push(link);
  }

  helpState = (mode) => {
    this.setState(() => ({ HelpPopup: mode }));
  };

  ruleState = (name, content) => {
    this.setState(() => ({
      game_name: name,
      rule_content: content,
    }));
  };

  displayRemainTime = (time) => {
    if (time) {
      return time > this.state.time ? Math.floor(Math.floor(Math.floor((time - this.state.time) / 1000) / 60) / 60) + ":" + (Math.floor(Math.floor((time - this.state.time) / 1000) / 60) % 60).toString().padStart(2, "0") + ":" + (Math.floor((time - this.state.time) / 1000) % 60).toString().padStart(2, "0") : this.props.i18n.t("m_game.ended");
    } else {
      return this.props.i18n.t("m_game.not_start");
    }
  };

  refresh_slider() {
    window.axios.get(`/api/game/mode/${this.props.gameId}`).then((response) => {
      if (response.data.g) {
        this.setState({ g: response.data.g });
      }
      if (response.data.v) {
        this.setState({ v: response.data.v });
      }
      if (response.data.s) {
        this.setState({ s: response.data.s });
      }
      if (response.data.m) {
        this.setState({ m: response.data.m });
      }
    });
  }

  render() {
    return (
      <>
        <Swiper
          id="mode_slider"
          data-content={this.props.i18n.t("tutorial.mode_slider")}
          style={{ marginTop: "2vh" }}
          modules={[EffectCoverflow, Pagination, Navigation, Keyboard]}
          keyboard={{ enabled: true }}
          pagination={true}
          navigation={true}
          centeredSlides={false}
          centerInsufficientSlides={true}
          spaceBetween={30}
          breakpoints={{
            425: {
              slidesPerView: 1.5,
            },
            768: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          }}
          coverflowEffect={{
            rotate: 0,
            stretch: -10,
            depth: 200,
            modifier: 1,
            slideShadows: false,
          }}
          grabCursor={true}
          className="mySwiper noselect">
          {this.state.g.map((gacha) => (
            <SwiperSlide key={gacha.id} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <a href={gacha.link}>
                <div className="position-relative" style={{ height: "55vh" }}>
                  <img src={`/gacha/blank/${gacha.color}_machine.webp`} className="position-relative img-fluid h-100" />
                  <img src={gacha.cover_url} className="img-fluid mx-auto position-absolute" style={{ top: "15%", left: "0px", right: "0px", width: "80%" }} />
                  <p className="position-absolute text-bg-light text-center rounded-top w-100 text-truncate" style={{ bottom: "27%" }}>
                    {gacha[`name_${this.props.i18n.language}`]}
                  </p>
                </div>
              </a>
            </SwiperSlide>
          ))}
          {this.state.m.map((mode) => (
            <SwiperSlide key={mode.id}>
              <Card variant="outlined" className="game-card" sx={{ backgroundColor: mode.color ?? "#7311a4", color: "white" }}>
                <CardMedia component="img" image={mode.game_banner} />
                <CardContent>
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xs
                      display="flex"
                      alignItems="center"
                      onClick={() => {
                        this.ruleState(mode[`name_${this.props.i18n.language}`] ?? this.props.i18n.t(`game_lobby.m_mode`), mode.remarks);
                      }}>
                      <Typography variant="h6">{mode[`name_${this.props.i18n.language}`] ?? this.props.i18n.t(`game_lobby.m_mode`)}</Typography>
                      <FaRegQuestionCircle size="1.25rem" style={{ marginLeft: "0.5em" }} />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center" justifyContent="end">
                      <Typography variant="h5">{this.displayRemainTime(mode.end_time)}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Grid container spacing={0}>
                    {mode.players && (
                      <Grid item xs={12} display="flex" alignItems="center">
                        {mode.players
                          .map((players, idx) => {
                            let items = [];
                            for (let i = 0; i < parseInt(players); i++) {
                              items.push(<FaUser key={`${mode.id}-${players}-${i}`} />);
                            }
                            return <div key={idx} style={{display:"flex"}}>{items}</div>;
                          })
                          .reduce((prev, curr) => [prev, "|", curr])}
                        ({mode[`angle_${this.props.i18n.language}`].join("/")})
                      </Grid>
                    )}
                    {mode.match_type_eng && (
                      <Grid item xs={12} display="flex" alignItems="center">
                        {this.props.i18n.t("pubg.match_type")}: {mode[`match_type_${this.props.i18n.language}`].join("/")}
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {this.props.i18n.t("game_lobby.players")}: {mode.player}
                    </Grid>
                    <Grid container item xs={12} alignItems="center">
                      <Grid item xs={12} display="flex" alignItems="center" justifyContent="start">
                        {this.props.i18n.t("m_game.highest_payout")}: {mode.max_prize}<img src={mode.reward_icon} style={{ height: "1.25rem" }} />
                      </Grid>
                    </Grid>
                    <Grid container item xs={10} alignItems="end">
                      <Grid item xs={12} display="flex" alignItems="center" justifyContent="end">
                        <Typography variant="body2">{this.props.i18n.t("game_lobby.entry_fee")}</Typography>
                        <Typography variant="body2" style={{ paddingLeft: "0.5em" }}>
                          {mode.entry_fee}
                        </Typography>
                        <img src={mode.cost_icon} style={{ height: "0.875rem" }} />
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <img src={ForwardNoShadow} onClick={() => this.handleLink(mode.detail_link)} width="100%" />
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </SwiperSlide>
          ))}
          {this.state.s.length && (
            <SwiperSlide key="s">
              <Card variant="outlined" className="game-card" sx={{ backgroundColor: "#c52920", color: "white" }}>
                <CardMedia component="img" image={this.state.s[0].game_banner} />
                <CardContent>
                  <Grid
                    display="flex"
                    alignItems="center"
                    onClick={() => {
                      this.helpState("s");
                    }}>
                    <Typography variant="h6">{this.props.i18n.t(`game_lobby.s_mode`)}</Typography>
                    <FaRegQuestionCircle size="1.25rem" style={{ marginLeft: "0.5em" }} />
                  </Grid>
                </CardContent>
                {this.state.s.map((mode) => (
                  <CardActions key={mode.id}>
                    <Grid container spacing={0}>
                      <Grid container item xs={10}>
                        <Grid item xs={12} display="flex" alignItems="center" justifyContent="start">
                          <Typography variant="h5">{mode[`max_prize_name_${this.props.i18n.language}`]}</Typography>
                          <Typography variant="h5" style={{ paddingLeft: "0.5em" }}>
                            {mode.max_prize}
                          </Typography>
                          <img src={mode.reward_icon} style={{ height: "1.25rem" }} />
                        </Grid>
                        <Grid item xs={12} display="flex" alignItems="center" justifyContent="end">
                          <Typography variant="body2">{this.props.i18n.t("game_lobby.entry_fee")}</Typography>
                          <Typography variant="body2" style={{ paddingLeft: "0.5em" }}>
                            {mode.entry_fee}
                          </Typography>
                          <img src={mode.cost_icon} style={{ height: "0.875rem" }} />
                        </Grid>
                      </Grid>
                      <Grid item xs={2}>
                        <img src={ForwardNoShadow} onClick={() => this.handleLink(mode.detail_link)} width="100%" />
                      </Grid>
                    </Grid>
                  </CardActions>
                ))}
              </Card>
            </SwiperSlide>
          )}
          {this.state.v.length && (
            <SwiperSlide key="v">
              <Card variant="outlined" className="game-card" sx={{ backgroundColor: "#ffd55a", color: "black" }}>
                <CardMedia component="img" image={this.state.v[0].game_banner} />
                <CardContent>
                  <Grid
                    display="flex"
                    alignItems="center"
                    onClick={() => {
                      this.helpState("v");
                    }}>
                    <Typography variant="h6">{this.props.i18n.t(`game_lobby.v_mode`)}</Typography>
                    <FaRegQuestionCircle size="1.25rem" style={{ marginLeft: "0.5em" }} />
                  </Grid>
                </CardContent>
                {this.state.v.map((mode) => (
                  <CardActions key={mode.id}>
                    <Grid container spacing={0}>
                      <Grid container item xs={10}>
                        <Grid item xs={12} display="flex" alignItems="center" justifyContent="start">
                          <Typography variant="h5">{mode[`max_prize_name_${this.props.i18n.language}`]}</Typography>
                          <Typography variant="h5" style={{ paddingLeft: "0.5em" }}>
                            {mode.max_prize}
                          </Typography>
                          <img src={mode.reward_icon} style={{ height: "1.25rem" }} />
                        </Grid>
                        <Grid item xs={12} display="flex" alignItems="center" justifyContent="end">
                          <Typography variant="body2">{this.props.i18n.t("game_lobby.entry_fee")}</Typography>
                          <Typography variant="body2" style={{ paddingLeft: "0.5em" }}>
                            {mode.entry_fee}
                          </Typography>
                          <img src={mode.cost_icon} style={{ height: "0.875rem" }} />
                        </Grid>
                      </Grid>
                      <Grid item xs={2}>
                        <img src={ForwardNoShadow} onClick={() => this.handleLink(mode.detail_link)} width="100%" />
                      </Grid>
                    </Grid>
                  </CardActions>
                ))}
              </Card>
            </SwiperSlide>
          )}
        </Swiper>
        <GameRulePopup
          name={this.state.game_name}
          title={this.props.i18n.t("game_detail.game_rule")}
          rule={this.state.rule_content}
          setOpen={() => {
            this.ruleState(false, false);
          }}
        />
        <Help
          mode={this.state.HelpPopup}
          setMode={() => {
            this.helpState(false);
          }}
        />
      </>
    );
  }
}

export default withRouter(withTranslation()(ModeSlider));
