import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import changeAvatarCht from "~/assets/images/components/Auth/Profile/ChangeAvatarCht.svg";
import changeAvatarEng from "~/assets/images/components/Auth/Profile/ChangeAvatarEng.svg";
import PubgIDPopup from "~/components/Auth/PubgIDPopup";

import HelpOutline from "@mui/icons-material/HelpOutline";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { toast } from "react-toastify";


const theme = createTheme({
  palette: {
    primary: {
      light: "#cccccc",
      main: "#ffffff",
      dark: "#868686",
      contrastText: "#000",
    },
    secondary: {
      light: "#5d606f",
      main: "#35394C",
      dark: "#252735",
      contrastText: "#fff",
    },
  },
});

const changeAvatar = { cht: changeAvatarCht, eng: changeAvatarEng };

class Profile extends Component {
  constructor(props) {
    super(props);
    if (!window.apiToken) {
      this.props.history.push("/login");
    }
    this.state = {
      profile_pic: null,
      nickname: "",
      rees_id: null,
      gender: "",
      first_name: "",
      last_name: "",
      birthday: "",
      pubg_id: "",
      pubg_popup: false,
    };
    this.handleUserDataInputChange = this.handleUserDataInputChange.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
  }

  componentDidMount() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios.get(`/api/user?profile_pic&nickname&rees_id&gender&first_name&last_name&birthday&pubg_id`, { headers: { "Cache-Control": "no-cache", Pragma: "no-cache", Expires: "0" } }).then((response) => {
      this.setState({
        profile_pic: response.data.profile_pic,
        nickname   : response.data.nickname ?? "",
        rees_id    : response.data.rees_id ?? "",
        gender     : response.data.gender ?? "",
        first_name : response.data.first_name ?? "",
        last_name  : response.data.last_name ?? "",
        birthday   : response.data.birthday ?? "",
        pubg_id    : response.data.pubg_id ?? "",
      });
      document.getElementById("loading_curtain").classList.add("d-none");
    });
  }

  updateProfile() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios
      .post(`/api/user/profile/data`, this.state)
      .then((response) => {
        document.getElementById("loading_curtain").classList.add("d-none");
        window.sessionStorage.setItem("nickname", this.state.nickname);
        window.Sound_TwinkleAlert.play();
        toast.success(this.props.i18n.t("profile.changes_saved"), {
          containerId: "Toast_color_flip",
          autoClose: 3000,
        });
      });
  }

  handleUserDataInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <Grid container alignItems="center" justifyContent="center">
        <Grid container item xs={12} lg={3} alignItems="center" justifyContent="center" style={{ padding: "3vh 0px" }}>
          <Grid item xs={7} lg={12} display="flex" flexDirection="column" alignItems="center" justifyContent="center" color="white">
            <h2>{this.state.nickname}</h2>
          </Grid>
          <Grid item xs={5} lg={12} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <img src={this.state.profile_pic} width="50%" />
            <Link to={`/profile/avatar`} style={{ textDecoration: "none", textAlign: "center", marginTop: "5px" }}>
              <img src={changeAvatar[this.props.i18n.language]} width="60%" />
            </Link>
          </Grid>
        </Grid>
        <Grid container item xs={12} lg={7}>
          <ThemeProvider theme={theme}>
            <div
              style={{
                backgroundColor: "#35394C",
                width: "100%",
                marginBottom: "10px",
              }}>
              <ToggleButtonGroup color="primary" fullWidth={true} value={this.state.gender} exclusive onChange={this.handleUserDataInputChange} aria-label="text alignment">
                <ToggleButton sx={{ color: "#c0c0c0" }} color="primary" value="1" name="gender" type="radio" selected={this.state.gender == "1"}>
                  {this.props.i18n.t("profile.mr")}
                </ToggleButton>
                <ToggleButton sx={{ color: "#c0c0c0" }} color="primary" value="2" name="gender" type="radio" selected={this.state.gender == "2"}>
                  {this.props.i18n.t("profile.ms")}
                </ToggleButton>
                <ToggleButton sx={{ color: "#c0c0c0" }} color="primary" value="3" name="gender" type="radio" selected={this.state.gender == "3"}>
                  {this.props.i18n.t("profile.mx")}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </ThemeProvider>
          <Grid item xs={12} marginBottom="10px">
            <TextField
              type="text"
              size="small"
              label={this.props.i18n.t("profile.first_name")}
              name="first_name"
              onChange={this.handleUserDataInputChange}
              value={this.state.first_name}
              variant="filled"
              fullWidth={true}
              style={{ background: "#35394C" }}
              inputProps={{ style: { fontFamily: "Arial", color: "white" } }}
              InputLabelProps={{
                style: { fontFamily: "Arial", color: "white" },
              }}
            />
          </Grid>
          <Grid item xs={12} marginBottom="10px">
            <TextField
              type="text"
              size="small"
              label={this.props.i18n.t("profile.last_name")}
              name="last_name"
              onChange={this.handleUserDataInputChange}
              value={this.state.last_name}
              variant="filled"
              fullWidth={true}
              style={{ background: "#35394C" }}
              inputProps={{ style: { fontFamily: "Arial", color: "white" } }}
              InputLabelProps={{
                style: { fontFamily: "Arial", color: "white" },
              }}
            />
          </Grid>
          <Grid item xs={12} marginBottom="10px">
            <TextField
              type="text"
              size="small"
              label={this.props.i18n.t("profile.nickname")}
              name="nickname"
              onChange={this.handleUserDataInputChange}
              value={this.state.nickname}
              variant="filled"
              fullWidth={true}
              style={{ background: "#35394C" }}
              inputProps={{ style: { fontFamily: "Arial", color: "white" } }}
              InputLabelProps={{
                style: { fontFamily: "Arial", color: "white" },
              }}
            />
          </Grid>
          <Grid item xs={12} marginBottom="10px">
            <TextField
              size="small"
              label={this.props.i18n.t("profile.birthday")}
              name="birthday"
              onChange={this.handleUserDataInputChange}
              value={this.state.birthday}
              variant="filled"
              fullWidth={true}
              type="date"
              style={{ background: "#35394C" }}
              inputProps={{ style: { fontFamily: "Arial", color: "white" } }}
              InputLabelProps={{
                style: { fontFamily: "Arial", color: "white" },
              }}
            />
          </Grid>
          {/* <Grid container item xs={12} marginBottom="10px" container alignItems="center" color="white" style={{ background: "#35394C", padding: "10px" }}>
            <Grid item xs={3}>
              Mobile
            </Grid>
            <Grid item xs={9}>
              <PhoneInput international countryCallingCodeEditable={false} defaultCountry="HK" value={this.state.contact} onChange={() => {}} />
            </Grid>
          </Grid> */}
          <Grid item xs={11} marginBottom="10px">
            <TextField
              size="small"
              label={this.props.i18n.t("profile.pubg_id")}
              name="pubg_id"
              onChange={this.handleUserDataInputChange}
              value={this.state.pubg_id}
              variant="filled"
              fullWidth={true}
              style={{ background: "#35394C" }}
              inputProps={{ style: { fontFamily: "Arial", color: "white" } }}
              InputLabelProps={{
                style: { fontFamily: "Arial", color: "white" },
              }}
            />
          </Grid>
          <Grid item xs={1} marginBottom="10px" className="d-flex align-items-center justify-content-center">
            <HelpOutline style={{ color: "white" }} onClick={() => this.setState((prevState) => ({ pubg_popup: !prevState.pubg_popup }))} />
            <PubgIDPopup
              open={this.state.pubg_popup}
              setOpen={() => {
                this.setState((prevState) => ({ pubg_popup: !prevState.pubg_popup }));
              }}
            />
          </Grid>
          <Grid container item xs={12} alignItems="center" justifyContent="center" color="white">
            <Button
              variant="outlined"
              onClick={() => {
                this.updateProfile();
              }}>
              {this.props.i18n.t("profile.save")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(Profile);
