import React, { Component } from "react";
import Popup from "reactjs-popup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const contentStyle = {
  background: "#cfcece",
  width: "100%",
  maxWidth: "95vw",
  height: "80%",
  borderRadius: "10px",
};

class GameRulePopup extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Popup open={this.props.rule === false ? false : true} modal lockScroll closeOnDocumentClick={false} onClose={this.props.setOpen} nested {...{ contentStyle }}>
        {(close) => (
          <div className="bg-transparent modal modal-content border-0" style={{ height: "100%" }}>
            <button className="close" onClick={close}></button>
            <Grid item xs={12} style={{ height: "100%" }}>
              <Typography variant="h6">{this.props.name}</Typography>
              <Typography variant="h6">{this.props.title}</Typography>
              <div dangerouslySetInnerHTML={{ __html: this.props.rule }} style={{ overflow: "auto", position: "relative", height: "93%" }} />
            </Grid>
          </div>
        )}
      </Popup>
    );
  }
}

export default GameRulePopup;
