import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { grey } from "@mui/material/colors";
import GeneralPopup from "~/components/GeneralPopup";
import HelpOutline from "@mui/icons-material/HelpOutline";

import ReferalPopup from "~/components/ReferalPopup";
import QRPopup from "~/components/Wallet/QRPopup";

import Chasepuzzle from "~/assets/images/components/General/Chasepuzzle.svg";
import Codeicon from "~/assets/images/components/Wallet/Codeicon.svg";
import face from "~/assets/images/components/Dummy/HeadIcon/face_1.svg";
import greenicon from "~/assets/images/components/General/ExclamationMark.svg";
import SilverPuzzle from "~/assets/images/components/General/SilverPuzzle.svg";

class WalletIndex extends Component {
  constructor(props) {
    document.getElementById("loading_curtain").classList.remove("d-none");
    super(props);
    this.state = {
      profile: {
        nickname: null,
        profile_pic: null,
        balance: null,
        wallet: [],
      },
      notice: false,
      qr: false,
      amount: false,
      tab: 1,
      referal: false,
      has_mount: false,
      desc_popup: null,
    };
  }

  componentDidMount() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    if (!window.apiToken) {
      document.getElementById("loading_curtain").classList.add("d-none");
      this.props.history.push("/login");
    } else {
      window.axios.get(`/api/user?profile_pic&wallet&nickname&balance`).then((response) => {
        this.setState({
          profile: response.data,
        });
        document.getElementById("loading_curtain").classList.add("d-none");
      }).catch((errors) => {
        document.getElementById("loading_curtain").classList.add("d-none");
      }
      );
    }
  }

  render() {
    const Toplinkdata = [
      // { id: 0, linkName: "Transaction History", link: `/wallet/TranscationHistory` },
      // { id: 1, linkName: "Saved Cards", link: `/wallet/SaveCards` },
      // { id: 2, linkName: "Puzzle Packages", link: "/wallet/puzzle" },
      // { id: 3, linkName: "My Collection", link: "/wallet/MyCollection" },
      // { id: 4, linkName: "Refer Friends to Earn Puzzles", link: "/wallet" },
      { id: 0, linkName: this.props.i18n.t("wallet.transaction_history"), link: `#` },
      { id: 1, linkName: this.props.i18n.t("wallet.saved_cards"), link: `#` },
      { id: 2, linkName: this.props.i18n.t("wallet.puzzle_packages"), link: "#" },
      { id: 3, linkName: this.props.i18n.t("wallet.my_collection"), link: "#" },
      { id: 4, linkName: this.props.i18n.t("wallet.referal"), link: "#" },
    ];

    if (this.state.has_mount) {return (<></>);}

    return (
      <Grid container direction="row" justifyContent="center" alignItems="center">
        {/* Name */}
        <Grid item xs={12} sx={{ paddingBottom: "1vh" }}>
          <div style={{ textAlign: "center", backgroundColor: "#8e8b91" }}>
            {this.props.i18n.t("wallet.greeting")}
            {this.state.profile.nickname}
          </div>
        </Grid>
        {/* Estimate */}
        <Grid item xs={12} height="30vh">
          <Grid container justifyContent="center" alignItems="center" height="60%" style={{ backgroundImage: `url(${this.state.profile.profile_pic})`, backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
            {/* <img
              src={Codeicon}
              width="10%"
              style={{ position: "relative", left: 30, top: 40 }}
              onClick={() => {
                this.setState((prevState) => ({ ...prevState, qr: !prevState.qr }));
              }}
            /> */}
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="caption" color="white">
              {this.props.i18n.t("wallet.estimated_asset_value")}
            </Typography>
            {/* <ClickAwayListener
              onClickAway={() => {
                this.setState({ notice: false });
              }}>
              <div style={{ display: "inline" }}>
                <Tooltip
                  PopperProps={{ disablePortal: true }}
                  onClose={() => {
                    this.setState({ notice: false });
                  }}
                  open={this.state.notice}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={this.props.i18n.t("wallet.delay_notice")}>
                  <Button
                    sx={{ minWidth: "initial" }}
                    onClick={() => {
                      this.setState({ notice: true });
                    }}>
                    <Typography variant="caption" color="white">
                      {this.props.i18n.t("wallet.estimated_asset_value")}:
                      <img src={greenicon} style={{ width: "1em" }} />
                    </Typography>
                  </Button>
                </Tooltip>
              </div>
            </ClickAwayListener> */}
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="caption" color="white">
              {this.props.i18n.t("wallet.hkd_equiv", { amount: this.state.amount ? this.state.profile.balance : "******" })}
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  this.setState((prevState) => ({ ...prevState, amount: !prevState.amount }));
                }}>
                {this.state.amount ? <Visibility color="primary" /> : <VisibilityOff style={{ color: grey[300] }} />}
              </IconButton>
            </Typography>
          </Grid>
        </Grid>

        {/* <Grid item xs={12} height="4vh" container bgcolor="#8e8b91">
          <Grid
            item
            xs={6}
            height="4vh"
            onClick={() => {
              this.setState({ tab: 0 });
            }}
            style={{ borderBottom: this.state.tab === 0 ? "3px solid white" : "" }}></Grid>
          <Grid
            item
            xs={6}
            height="4vh"
            onClick={() => {
              this.setState({ tab: 1 });
            }}
            style={{ borderBottom: this.state.tab === 1 ? "3px solid white" : "" }}></Grid>
        </Grid> */}

        <Grid item xs={12} display={this.state.tab === 0 ? "block" : "none"}>
          <List>
            {Toplinkdata.slice(0, 4).map((text, index) => (
              <Link key={index} to={text.link} style={{ textDecoration: "none", color: "white" }}>
                <ListItem button key={text.id}>
                  <ListItemText>{text.linkName}</ListItemText>
                  <KeyboardArrowRightIcon />
                </ListItem>
              </Link>
            ))}
            <ListItem
              button
              style={{ color: "white" }}
              onClick={() => {
                this.setState((prevState) => ({ ...prevState, referal: !prevState.referal }));
              }}>
              <ListItemText> {Toplinkdata[4].linkName} </ListItemText>
              <KeyboardArrowRightIcon />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} display={this.state.tab === 1 ? "block" : "none"}>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography color="white">{this.props.i18n.t("wallet.asset_type")}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography color="white">{this.props.i18n.t("wallet.balance")}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.profile.wallet.map((data, i) => (
                  <TableRow key={i} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <img src={data.image} style={{ width: "5vw", float: "left", marginRight: "2vw" }} />
                      <HelpOutline
                        data-data={JSON.stringify({ cht: data.description_cht, eng: data.description_eng })}
                        style={{ color: "white", marginRight: "5vw", float: "left" }}
                        onClick={(e) => {
                          this.setState({ desc_popup: JSON.parse(e.currentTarget.dataset.data)[this.props.i18n.language] });
                        }}
                      />
                      <GeneralPopup
                        open={this.state.desc_popup}
                        setOpen={() => {
                          this.setState({ desc_popup: null });
                        }}
                      />
                      <Typography color="white">{data["name_" + this.props.i18n.language]}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography color="white">{data.amount}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <QRPopup
          open={this.state.qr}
          setOpen={() => {
            this.setState((prevState) => ({ ...prevState, qr: !prevState.qr }));
          }}
        />
        <ReferalPopup
          open={this.state.referal}
          setOpen={() => {
            this.setState((prevState) => ({ ...prevState, referal: !prevState.referal }));
          }}
        />
      </Grid>
    );
  }
}

export default withTranslation()(WalletIndex);