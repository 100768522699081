import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import "reactjs-popup/dist/index.css";
import "~/assets/styles/Popup.css";
import Typography from "@mui/material/Typography";
// import PlayAgain from "~/assets/images/components/GamePayout/PlayAgain.svg";
// import FreeShare from "~/assets/images/components/GamePayout/FreeShare.svg";
import BattleAgainCht from "~/assets/images/components/GamePayout/BattleAgainCht.svg";
import BattleAgainEng from "~/assets/images/components/GamePayout/BattleAgainEng.svg";
import Winner from "~/assets/images/components/GamePayout/Winner.svg";
import Loser from "~/assets/images/components/GamePayout/Bonus.svg";

const BattleAgain = { cht:BattleAgainCht, eng:BattleAgainEng };
class OneVOnePayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
      result: {
        is_ended   : null,
        image      : null,
        reward_icon: null,
        reward_qty : 0,
        note       : null,
        game_id    : null,
        winner     : true,
      },
    };
  }

  componentDidMount() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios
      .get(`/api/game/v/result/${this.props.match.params.record_id}`)
      .then((response) => {
      document.getElementById("loading_curtain").classList.add("d-none");
        this.setState({
          is_loaded: true,
          result: response.data,
        });
      });
  }

  render() {
    if (!this.state.is_loaded) {
      return <></>;
    } else if (!this.state.result.is_ended) {
      return (
        <div className="d-flex align-items-center justify-content-between flex-column">
          <div style={{ paddingTop: "15vh" }}>
            <p className="h4 text-center text-white" style={{ overflowWrap: "break-word" }}>
              {this.state.result[`note_${this.props.i18n.language}`]}
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex align-items-center justify-content-between flex-column" style={{ minHeight: "65vh", backgroundImage: `url(${this.state.result.winner ? Winner : Loser}),url(${this.state.result.image})`, backgroundPosition: "top center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}>
          <div style={{ paddingTop: "15vh" }}>
            {this.state.result.reward_icon ? (
              <div className="d-flex align-items-center justify-content-center">
                <img src={this.state.result.reward_icon} style={{ maxHeight: "3em" }} />
                <span className="h2 text-center text-white" style={{ overflowWrap: "break-word" }}>
                  ×{this.state.result.reward_qty}
                </span>
              </div>
            ) : null}
            <p className="h4 text-center text-white" style={{ overflowWrap: "break-word" }}>
              {this.state.result[`note_${this.props.i18n.language}`]}
            </p>
          </div>
          <div className="row w-100 justify-content-center">
            <div className="col-6 d-flex align-items-center justify-content-center">
              <Link to={`/lobby/${this.state.result.game_id}`}>
                <img src={BattleAgain[this.props.i18n.language]} className="img-fluid" />
              </Link>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(OneVOnePayout);
