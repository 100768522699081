import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Key from "~/assets/images/components/Auth/Register/Key.svg";
import { toast } from "react-toastify";

class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resend_disable: true,
      resend_bg: "#CCCCCC",
      countdown: 180,
    };
    this.startTimer = this.startTimer.bind(this);
    this.resendVerifyEmail = this.resendVerifyEmail.bind(this);
  }

  componentDidMount() {
    document.getElementById("loading_curtain").classList.add("d-none");
    this.startTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startTimer() {
    this.timer = setInterval(() => this.tick(), 1000);
  }

  tick() {
    if (this.state.countdown > 0) {
      this.setState((prevState) => ({ countdown: prevState.countdown - 1 }));
    } else {
      clearInterval(this.timer);
      this.setState({
        resend_disable: false,
        resend_bg: "#FF0000",
        countdown: 0,
      });
    }
  }

  resendVerifyEmail() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    this.setState(
      {
        resend_disable: true,
        resend_bg: "#CCCCCC",
        countdown: 180,
      },
      this.startTimer
    );
    window.axios
      .post(`api/email/resend`, { email: window.sessionStorage.register_email })
      .then((response) => {
        document.getElementById("loading_curtain").classList.add("d-none");
        switch (response.status) {
          case 202:
            window.Sound_TwinkleAlert.play();
            toast.success(this.props.i18n.t("register.verify_reminder", { email: response.data.email }), {
              containerId: "Toast_color_flip",
              autoClose: 3000,
            });
            break;
          case 204:
            window.Sound_TwinkleAlert.play();
            toast.success(this.props.i18n.t("register.verified_reminder"), {
              containerId: "Toast_color_flip",
              autoClose: 3000,
            });
            break;
        }
      })
  }

  render() {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12} height="5vh" display="flex" alignItems="center">
          {/* <Button onClick={this.props.history.goBack} variant="body2" sx={{ pl: "5vw" }} style={{ color: "white" }}>
            &lt; {this.props.i18n.t("register.back")}
          </Button> */}
        </Grid>
        <Grid item xs={10} height="18vh" display="flex" flexDirection="column" justifyContent="space-around" alignItems="center">
          <Typography variant="h6" color="white" textAlign="center">
            {this.props.i18n.t("register.verify_your_account")}
          </Typography>
          <Typography variant="body" color="white" textAlign="center">
            {this.props.i18n.t("register.verify_reminder", { email: window.sessionStorage.getItem("register_email") })}
          </Typography>
        </Grid>
        <Grid item xs={12} height="27vh" display="flex" justifyContent="center" alignItems="center">
          <img src={Key} style={{width:"50%", maxWidth:"300px"}} />
        </Grid>
        <Grid item xs={5.5} lg={3} height="10vh" display="flex" justifyContent="center" alignItems="center">
          <Button variant="outlined" onClick={this.resendVerifyEmail} disabled={this.state.resend_disable} style={{ width: "100%", backgroundColor: this.state.resend_bg, color: "white", borderColor: "white" }}>
            {this.state.resend_disable ? this.props.i18n.t("register.wait", { second: this.state.countdown }) : this.props.i18n.t("register.resend_email")}
          </Button>
        </Grid>
        <Grid item xs={5.5} lg={3} height="10vh" display="flex" justifyContent="center" alignItems="center">
          <Button variant="outlined" component={Link} to="/intro" style={{ width: "100%", backgroundColor: "#5C2D80", color: "white", borderColor: "white" }}>
            {this.props.i18n.t("register.sign_in_now")}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(Verify);
