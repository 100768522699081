import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";


class Receipt extends Component {
  constructor() {
    super();
    this.state = {
      title: {cht: null, eng: null},
      order_id: null,
      order_time: null,
      amount: {cash: null},
      is_fetching: false,
      has_mount: false,
    };
  }

  componentDidMount() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios.get(`/api/receipt/${this.props.match.params.order_id}`).then((response) => {
      this.setState(response.data);
      document.getElementById("loading_curtain").classList.add("d-none");
    });
  }

  render() {
    if (!this.state.title) {return <></>;}
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" style={{ background: "#242634", paddingTop: "5vh", paddingBottom: "5vh" }}>
        <Grid item xs={11} style={{ paddingTop: "2vh", paddingBottom: "2vh", borderBottom: "1px solid white" }}>
          <Grid item xs={3} alignItems="center">
            <img src={this.state.logo} style={{ maxWidth: "15vw", width: "100%" }} />
          </Grid>
          <Grid item xs={8} alignItems="center" justifyItems="end">
            <Typography variant="body1" color="white">
              {this.props.i18n.t("receipt.receipt")}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={11} style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
          <Grid item xs={6} alignItems="center">
            <Typography color="white" variant="body2">
              {this.props.i18n.t("receipt.order_id")}
              <br />
              {this.state.order_id}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={11} style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
          <Typography color="white" variant="body2">
            {this.props.i18n.t("receipt.date")}
            <br />
            {this.state.order_time}
          </Typography>
        </Grid>

        <Grid item xs={11} style={{ paddingTop: "2vh", paddingBottom: "2vh", fontWeight: 900 }}>
          <Typography variant="h6" color="white">
            {this.state.title[this.props.i18n.language]}
          </Typography>
        </Grid>

        <Grid item xs={11} container style={{ paddingTop: "2vh", paddingBottom: "2vh" }}>
          <Grid item xs={1} display="flex" alignItems="center">
            <ShoppingCartOutlinedIcon style={{ color: "#FFFFFF" }} />
          </Grid>
          <Grid item xs={5} alignItems="center">
            <Typography color="white" variant="body2">
              {this.props.i18n.t("receipt.product")}
              <br />
              {this.state.title[this.props.i18n.language]}
            </Typography>
          </Grid>
          <Grid item xs={1} display="flex" alignItems="center">
            <PaidOutlinedIcon style={{ color: "#FFFFFF" }} />
          </Grid>
          <Grid item xs={5} alignItems="center">
            <Typography color="white" variant="body2">
              {this.props.i18n.t("receipt.amount")}
              <br />
              {this.state.amount.cash}
            </Typography>
          </Grid>
        </Grid>

        {/* <Grid item xs={10} height="18vh" display="flex" flexDirection="column" justifyContent="space-around" alignItems="center">
          <Typography variant="h6" color="white" textAlign="center">
            {this.props.i18n.t("purchase_success.title", { title: this.state.title[this.props.i18n.language] })}
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" flexDirection="column" justifyContent="space-around" alignItems="center">
          <Typography variant="subtitle2" color="white" textAlign="center">
            {this.props.i18n.t("purchase_success.description", { order_id: this.state.order_id, order_time: this.state.order_time })}
          </Typography>
        </Grid>
        <Grid item xs={12} height="27vh" display="flex" justifyContent="center" alignItems="center">
          <img src={Key} width="50%" />
        </Grid>
        <Grid item xs={6} height="10vh" display="flex" justifyContent="center" alignItems="center">
          <Button variant="outlined" component={Link} to={window.apiToken ? "/transaction" : "/index"} style={{ width: "100%", backgroundColor: "#5C2D80", color: "white", borderColor: "white" }}>
            {window.apiToken ? this.props.i18n.t("purchase_success.return") : this.props.i18n.t("purchase_success.login")}
          </Button>
        </Grid> */}
      </Grid>
    );
  }
}

export default withTranslation()(Receipt);