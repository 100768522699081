import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PoweredBy from "~/assets/images/components/General/PoweredByPuzzleChase.svg";

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: [],
    };
  }

  componentDidMount() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios.get(`/api/event`).then((response) => {
      this.setState({ event: response.data });
      document.getElementById("loading_curtain").classList.add("d-none");
    });
  }
  render() {
    if (this.state.event.length == 0) {return <></>;}
    return (
      <Grid container justifyContent="center" alignItems="center">
        {this.state.event.map((event, index) => (
          <Grid item xs={11} key={index} display="flex" justifyContent="center" alignItems="center" flexDirection="column" paddingTop="2vh" paddingBottom="1vh">
            <a target="_parent" href={event.link ?? "#"}>
              <img src={event[`banner_url_${this.props.i18n.language}`]} />
            </a>
            <Typography variant="caption" color="white" display="inline" style={{ display: "inline-block", wordBreak: "break-all" }}>
              {event[`description_${this.props.i18n.language}`]}
            </Typography>
          </Grid>
        ))}

        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
          <img src={PoweredBy} width="30%" />
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(Events);
