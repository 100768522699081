import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import GameChooserButton from "~/components/GameChooserButton";
import GameSlider from "~/components/GameSlider";
import TutorialVideoPopup from "~/components/TutorialVideoPopup";
import ReferalPopup from "~/components/ReferalPopup";
import Scrollbanner from "~/components/ScrollBanner";
import Scrolltext from "~/components/ScrollText";
import Referal from "~/assets/images/components/ReferalPopup/Referal.svg";


class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: null,
      show_tutorial: false,
    };
    this.setOpen = this.setOpen.bind(this);
  }

  componentDidMount() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.addEventListener('load', this.handleLoad);
    window.axios.get(`/api/index`, { cache: { maxAge: 1000 } }).then((response) => {
      this.setState({ data: response.data });
      if ((this.props.history.location.state ? this.props.history.location.state.prevPath : null) == "intro") {
        this.start_tutorial();
      }
      document.getElementById("loading_curtain").classList.add("d-none");
    });
  }

  start_tutorial() {
    Swal.fire({
      text: this.props.i18n.t("tutorial.intro"),
      confirmButtonText: this.props.i18n.t("tutorial.start_tutorial"),
      background: "#242634",
      color: "#fff",
    }).then(() => tutorial("GameSlider"));
  }

  setOpen() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  render() {
    if (!this.state.data) {
      return <></>;
    }
    return (
      <Box sx={{ flexGrow: 1 }} height="100%">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={11}>
            <div style={{ textAlign: "center" }}>
              <Scrollbanner data={this.state.data.banners} />
            </div>
          </Grid>
          <Grid item xs={11} style={{ marginTop: "1vh", marginBottom: "1vh" }}>
            <Scrolltext data={this.state.data[`scroll_text_${this.props.i18n.language}`]} />
          </Grid>
          <Grid item xs={12} md={11}>
            <GameSlider />
          </Grid>
          <Grid item xs={11} md={8} lg={5} style={{ textAlign: "center" }}>
            <div className="button">
              <img src={this.state.data.bottom_banner} width="100%" onClick={() => this.setOpen()} />
            </div>
            <TutorialVideoPopup
              open={this.state.open}
              setOpen={() => {
                this.setOpen();
              }}
            />
          </Grid>
          <GameChooserButton />
        </Grid>
      </Box>
    );
  }
}

export default withTranslation()(MainPage);
