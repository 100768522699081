import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "~/components/GameSlider.jsx";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import GoGameLobby from "~/assets/images/components/GameSlider/GoGameLobby.svg";
// Import Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";

class GameSlider extends Component {
  constructor(props) {
    super(props);
    if (!window.localStorage.getItem("game_slider_position")) {
      window.localStorage.setItem("game_slider_position", 0);
    }
    this.state = {
      gameSlider: [],
    };
    this.handleLink = this.handleLink.bind(this);
  }
  componentDidMount() {
    if (this.state.gameSlider.length == 0) {
      this.refresh_slider();
    }
  }

  handleLink(link) {
    window.localStorage.setItem("game_slider_position", link);
    window.Sound_NewSuccess.play();
    this.props.history.push(`/lobby/${link}`);
  }
  refresh_slider() {
    window.axios.get("/api/game/all").then((response) => {
      this.setState({
        gameSlider: response.data,
      });
      if (!window.localStorage.getItem("game_slider_position")) {
        window.localStorage.setItem("game_slider_position", Math.floor(this.state.gameSlider.length / 2));
      }
    });
  }

  render() {
    if (this.state.gameSlider.length > 0) {
      return (
        <Grid item container xs={12} style={{ marginBottom: "1.5vh" }}>
          <Swiper
            id="GameSlider"
            data-content={this.props.i18n.t("tutorial.game_slider")}
            data-target="GameSelector"
            modules={[EffectCoverflow]}
            onSlideChange={(swiper) => {
              if (swiper.slides[swiper.activeIndex] != undefined) {
                window.localStorage.setItem("game_slider_position", swiper.slides[swiper.activeIndex].dataset.gameId);
              }
            }}
            loop={true}
            centeredSlides
            initialSlide={this.state.gameSlider.findIndex((slider) => slider.id === window.localStorage.getItem("game_slider_position"))}
            slidesPerView={1.5}
            style={{ marginTop: 0 }}
            grabCursor={true}
            breakpoints={{
              576: {
                slidesPerView: 1.5,
              },
              768: {
                slidesPerView: 2.5,
              },
              992: {
                slidesPerView: 3.5,
              },
            }}
            effect={"coverflow"}
            coverflowEffect={{
              rotate: 0,
              stretch: -10,
              depth: 200,
              modifier: 1,
              slideShadows: false,
            }}
            className="mySwiper noselect">
            {this.state.gameSlider.map((game) => (
              <SwiperSlide data-game-id={game.id} key={game.id}>
                <Typography style={{ textAlign: "center" }} color="white" paragraph variant="body2">
                  <img style={{ maxWidth: "40vw", borderRadius: "20px" }} src={game.src} onClick={() => this.handleLink(game.id)} />
                  <br />
                  {game[`name_${this.props.i18n.language}`]}
                  <br />
                  {game.playing} {this.props.i18n.t("main.playing_now")}
                </Typography>
              </SwiperSlide>
            ))}
            <img
              onClick={() => this.handleLink(window.localStorage.getItem("game_slider_position"))}
              src={GoGameLobby}
              style={{
                width: "2.5rem",
                position: "absolute",
                zIndex: 2,
                bottom: "15%",
                left: "calc(50% + 8vmin)",
              }}
            />
          </Swiper>
        </Grid>
      );
    } else {
      return <></>;
    }
  }
}

export default withRouter(withTranslation()(GameSlider));
