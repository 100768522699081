import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MobileDetect from "mobile-detect";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";

import GameDetail from "~/components/Game/GameDetail";
import GameMPayoutPopup from "~/components/Game/GameMPayoutPopup";
import GameMStartPopup from "~/components/Game/GameMStartPopup";

import Chasepuzzle from "~/assets/images/components/General/Chasepuzzle.svg";
import Forward from "~/assets/images/components/General/Forward.svg";
import Headicon from "~/assets/images/components/Dummy/HeadIcon/face_1.svg";

import Badge_01 from "~/assets/images/components/GameM/Badge_01.svg"
import Badge_02 from "~/assets/images/components/GameM/Badge_02.svg"
import Badge_03 from "~/assets/images/components/GameM/Badge_03.svg"
import Badge_04 from "~/assets/images/components/GameM/Badge_04.svg"
import Badge_05 from "~/assets/images/components/GameM/Badge_05.svg"
import Badge_06 from "~/assets/images/components/GameM/Badge_06.svg"
import Badge_07 from "~/assets/images/components/GameM/Badge_07.svg"
import Badge_08 from "~/assets/images/components/GameM/Badge_08.svg"
import Badge_09 from "~/assets/images/components/GameM/Badge_09.svg"
import Badge_10 from "~/assets/images/components/GameM/Badge_10.svg"
import Badge_11 from "~/assets/images/components/GameM/Badge_11.svg"
import Badge_12 from "~/assets/images/components/GameM/Badge_12.svg"
import Bluebg from "~/assets/images/components/GameM/BlueBg.svg";
import Pinkbg from "~/assets/images/components/GameM/PinkBg.svg";
import Blackbg from "~/assets/images/components/GameM/Blackbg.svg";
import Greenbg from "~/assets/images/components/GameM/GreenBg.svg";
import HalfPinkbg from "~/assets/images/components/GameM/HalfPinkBg.svg";
import MyPuzzleCht from "~/assets/images/components/GameM/MyPuzzleCht.svg";
import MyPuzzleEng from "~/assets/images/components/GameM/MyPuzzleEng.svg";
import MyRankingCht from "~/assets/images/components/GameM/MyRankingCht.svg";
import MyRankingEng from "~/assets/images/components/GameM/MyRankingEng.svg";
import MyGameRankingCht from "~/assets/images/components/GameM/MyGameRankingCht.svg";
import MyGameRankingEng from "~/assets/images/components/GameM/MyGameRankingEng.svg";
import MyScoreCht from "~/assets/images/components/GameM/MyScoreCht.svg";
import MyScoreEng from "~/assets/images/components/GameM/MyScoreEng.svg";
import ScoreTBG from "~/assets/images/components/GameM/ScoreTBG.svg";
import CurrentPuzzlechaseRankingCht from "~/assets/images/components/GameM/CurrentPuzzlechaseRankingCht.svg";
import CurrentPuzzlechaseRankingEng from "~/assets/images/components/GameM/CurrentPuzzlechaseRankingEng.svg";
import share from "~/assets/images/components/GameM/Share.svg";
import { FaInfoCircle } from "react-icons/fa";
import { toast } from "react-toastify";

const md = new MobileDetect(navigator.userAgent);
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 9,
    top: 15,
    backgroundColor: "unset",
  },
}));

const BadgeIcon = {1:Badge_01,2:Badge_02,3:Badge_03,4:Badge_04,5:Badge_05,6:Badge_06,7:Badge_07,8:Badge_08,9:Badge_09,10:Badge_10,11:Badge_11,12:Badge_12};
const CurrentPuzzlechaseRanking = { cht: CurrentPuzzlechaseRankingCht, eng: CurrentPuzzlechaseRankingEng };
const MyGameRanking = { cht: MyGameRankingCht, eng: MyGameRankingEng };
const MyPuzzle = { cht: MyPuzzleCht, eng: MyPuzzleEng };
const MyRanking = { cht: MyRankingCht, eng: MyRankingEng };
const MyScore = { cht: MyScoreCht, eng: MyScoreEng };

class BattleModeJoin extends Component {
  constructor(props) {
    document.getElementById("loading_curtain").classList.remove("d-none");
    super(props);
    this.state = {
      id                : null,
      entryFee          : null,
      discount          : null,
      rewardIcon        : null,
      costIcon          : null,
      totalPlayer       : null,
      highestPayout     : null,
      currentPayout     : null,
      currentPlayer     : null,
      end_time          : null,
      ranks             : [],
      myRanks           : [],
      reward_table      : [],
      open_reward_table : [],
      game_m_start_popup: false,
      time              : Math.floor(new Date()),
      formula           : null,
    };
    this.Mode = 1;
    this.joinGame = this.joinGame.bind(this);
  }

  componentDidMount() {
    window.axios
      .get(`/api/game/m/detail/${this.props.match.params.entryid}`)
      .then((response) => {
        this.setState(response.data);
        document.getElementById("loading_curtain").classList.add("d-none");
      });
    this.intervalID = setInterval(() => this.tick(), 1000);
  }

  tick() {
    this.setState({time: Math.floor(new Date())});
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  joinGame() {
    window.Sound_Success.play();
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios
      .post(`/api/game/m/join`, {id: this.props.match.params.entryid,})
      .then((response) => {
        document.getElementById("loading_curtain").classList.add("d-none");
      });
  }

  render() {
    return (
      <>
        <GameDetail gameId={this.props.match.params.id} modeId="m" />
        <Grid container padding={1} spacing={1} justifyContent="center">
          <Grid item xs={12} md={2} lg={2} spacing={1} container justifyContent="center">
            <Grid item xs={6} md={12}>
              <div style={{ textAlign: "center", backgroundImage: `url(${Bluebg})`, backgroundSize: "cover", border: "2px solid #ffffff", borderRadius: "10px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <Typography variant="button" component="div" sx={{ textAlign: "center" }} color="white">
                  {this.props.i18n.t("m_game.highest_payout")}
                </Typography>
                <Typography variant="h6" component="div" sx={{ textAlign: "center", display: "flex", alignItems: "center" }} color="white">
                  {this.state.highestPayout} <img src={this.state.rewardIcon} style={{ height: "1em" }} />
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6} md={12}>
              {this.state.reward_table.length > 0 ? (
                <div
                  style={{ textAlign: "center", backgroundImage: `url(${Pinkbg})`, backgroundSize: "cover", border: "2px solid #ffffff", borderRadius: "10px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                  onClick={() => {
                    this.setState({ open_reward_table: this.state.reward_table });
                  }}>
                  <Typography variant="h6" sx={{ textAlign: "center" }} color="white">
                    {this.props.i18n.t("m_game.payout_details")}
                  </Typography>
                </div>
              ) : (
                <div style={{ textAlign: "center", backgroundImage: `url(${Pinkbg})`, backgroundSize: "cover", border: "2px solid #ffffff", borderRadius: "10px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                  <Typography variant="button" component="div" sx={{ textAlign: "center" }} color="white">
                    {this.props.i18n.t("m_game.current_payout")}
                  </Typography>
                  <Typography variant="h6" component="div" sx={{ textAlign: "center", display: "flex", alignItems: "center" }} color="white">
                    {this.state.currentPayout} / {this.state.highestPayout} <img src={this.state.rewardIcon} style={{ height: "1em" }} />
                  </Typography>
                </div>
              )}
            </Grid>
            <Grid item xs={6} md={12}>
              <div style={{ textAlign: "center", backgroundImage: `url(${Blackbg})`, backgroundSize: "cover", border: "2px solid #ffffff", borderRadius: "10px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <Typography variant="button" component="div" sx={{ textAlign: "center" }} color="white">
                  {this.props.i18n.t("m_game.players")}
                </Typography>
                <Typography variant="h6" component="div" sx={{ textAlign: "center", display: "flex", alignItems: "center" }} color="white">
                  {this.state.currentPlayer}/{this.state.totalPlayer}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6} md={12}>
              <div style={{ textAlign: "center", backgroundImage: `url(${Blackbg})`, backgroundSize: "cover", border: "2px solid #ffffff", borderRadius: "10px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <Typography variant="button" component="div" sx={{ textAlign: "center" }} color="white">
                  {this.props.i18n.t("m_game.remain_time")}
                </Typography>
                <Typography variant="h6" component="div" sx={{ textAlign: "center", display: "flex", alignItems: "center" }} color="white">
                  {this.state.end_time == null ? this.props.i18n.t("m_game.not_start") : this.state.end_time > this.state.time ? Math.floor(Math.floor(Math.floor((this.state.end_time - this.state.time) / 1000) / 60) / 60) + ":" + (Math.floor(Math.floor((this.state.end_time - this.state.time) / 1000) / 60) % 60).toString().padStart(2, "0") + ":" + (Math.floor((this.state.end_time - this.state.time) / 1000) % 60).toString().padStart(2, "0") : this.props.i18n.t("m_game.ended")}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid id="rankboard" data-content={this.props.i18n.t("tutorial.m_mode.ranks")} data-target="rule_btn" item xs={12} md lg>
            <Grid item xs={7} md={3} lg={3} style={{ display: "flex" }}>
              <img style={{ width: "100%" }} src={CurrentPuzzlechaseRanking[this.props.i18n.language]} />
            </Grid>
            <Grid item xs={5} md={9} lg={9} />
            <Grid item xs={12} style={{ backgroundImage: `url(${ScoreTBG})`, backgroundSize: "contain", backgroundRepeat: "repeat", position: "relative", border: "2px solid white", borderRadius: "10px", borderTopLeftRadius: "0px", height: "55vh" }}>
              <Grid container spacing={2} style={{ position: "relative", marginTop: "0px", height: "100%" }} className="scrollhost" justifyContent="space-evenly" alignItems="stretch" alignContent="flex-start">
                {this.state.ranks.map((ScoreData) => (
                  <Grid item xs={4} md={3} lg={2} key={ScoreData.rank} style={{ display: "flex", placeContent: "center" }}>
                    <StyledBadge badgeContent={<img src={BadgeIcon[ScoreData.rank]} style={{ width: "1rem" }} />} color="secondary" style={{ textAlign: "center", display: "flex", placeItems: "center", flexDirection: "column", color: "white", overflow: "hidden", width: "fit-content" }}>
                      <img src={ScoreData.user.profile_pic} width="85%" style={{ maxWidth: "4em" }} />
                      <span
                        style={{
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                          overflow: "hidden",
                        }}>
                        {ScoreData.user.name}
                      </span>
                      <span>
                        {ScoreData.score == null ? this.props.i18n.t("m_game.not_finish") : ScoreData.score}
                        {ScoreData.formula && (
                          <FaInfoCircle
                            size="1rem"
                            style={{ marginLeft: "0.5em" }}
                            onClick={() => {
                              this.setState({ formula: ScoreData.formula });
                            }}
                          />
                        )}
                      </span>
                      {ScoreData.score != null && (
                        <span>
                          {ScoreData.quantity}
                          <img src={ScoreData.type} style={{ height: "1em" }} />
                        </span>
                      )}
                    </StyledBadge>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          {/* {this.state.myRanks.length > 0 && ( */}
          <Grid id="my_rankboard" data-content={this.props.i18n.t("tutorial.m_mode.my_ranks")} item xs={12} md={4} lg={4}>
            <Grid item xs={7} md={4.5} lg={4.5} style={{ display: "flex" }}>
              <img style={{ width: "100%" }} src={MyGameRanking[this.props.i18n.language]} />
            </Grid>
            <Grid item xs={5} md={7.5} lg={7.5} />
            <div style={{ backgroundImage: `url(${ScoreTBG})`, backgroundSize: "contain", backgroundRepeat: "repeat", position: "relative", border: "2px solid white", borderRadius: "10px", borderTopLeftRadius: "0px" }}>
              <Grid container spacing={2} textAlign="center">
                <Grid item xs={4}>
                  <img src={MyRanking[this.props.i18n.language]} style={{ width: "100%" }} />
                </Grid>
                <Grid item xs={4}>
                  <img src={MyScore[this.props.i18n.language]} style={{ width: "100%" }} />
                </Grid>
                <Grid item xs={4}>
                  <img src={MyPuzzle[this.props.i18n.language]} style={{ width: "100%" }} />
                </Grid>
              </Grid>
              {this.state.myRanks.map((myrank, index) => {
                return (
                  <Grid key={index} container spacing={2} textAlign="center">
                    <Grid item xs={4} color="white">
                      {myrank.rank}
                    </Grid>
                    <Grid item xs={4} color="white">
                      {myrank.score}
                    </Grid>
                    <Grid item xs={4} color="white">
                      {myrank.quantity}
                      <img src={myrank.type} style={{ height: "1em" }} />
                    </Grid>
                  </Grid>
                );
              })}
            </div>
          </Grid>
          {/* )} */}
          {(this.state.time < this.state.end_time || !this.state.end_time) && (
            <Grid container item xs={12} md={3} lg={12}>
              <Grid item xs={3} md={12} lg={3} />
              <Grid
                item
                xs={4.5}
                md={12}
                lg={4.5}
                height="9vh"
                style={{
                  position: "relative",
                  paddingTop: "1vh",
                  left: "3.5vh",
                  textAlign: "center",
                  // backgroundImage     : `url(${Greenbg})`,
                  backgroundSize: "100% 100%",
                  backgroundAttachment: "initial",
                }}>
                {/* <Typography color="white" style={{ width: "88%" }}>
                  {this.props.i18n.t("m_game.share_for_free_coin")}
                </Typography> */}
              </Grid>
              <Grid
                id="start_btn"
                data-content={this.props.i18n.t("tutorial.m_mode.start")}
                data-target="my_rankboard"
                item
                xs={4.5}
                md={12}
                lg={4.5}
                height="9vh"
                onClick={this.joinGame}
                style={{
                  paddingTop: "0vh",
                  textAlign: "center",
                  backgroundImage: `url(${HalfPinkbg})`,
                  backgroundSize: "100% 100%",
                  backgroundAttachment: "initial",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}>
                <Typography variant="body1" color="white" paddingTop="0.5vh">
                  {this.props.i18n.t("m_game.entry_fee")}
                </Typography>
                <Typography variant="h6" color="white" component="div" sx={{ textAlign: "center", display: "flex", alignItems: "center" }}>
                  {this.state.discount ? (
                    <span>
                      <del>{this.state.entryFee}</del>
                      <span>{this.state.discount}</span>
                    </span>
                  ) : (
                    <span>{this.state.entryFee}</span>
                  )}
                  <img src={this.state.costIcon} style={{ height: "1em" }} />
                </Typography>
                <img src={Forward} style={{ position: "absolute", zIndex: 2, right: "0", bottom: "0%", width: "3rem" }} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <GameMPayoutPopup
          payouts={this.state.open_reward_table}
          setOpen={() => {
            this.setState({ open_reward_table: [] });
          }}
        />
        <GameMStartPopup
          open={this.state.game_m_start_popup}
          setOpen={() => {
            this.setState((prevState) => ({ game_m_start_popup: !prevState.game_m_start_popup }));
          }}
        />
        <Dialog
          onClose={() => {
            this.setState({ formula: null });
          }}
          open={this.state.formula ? true : false}>
          <div dangerouslySetInnerHTML={{ __html: this.state.formula }}></div>
        </Dialog>
      </>
    );
  }
}

export default withTranslation()(BattleModeJoin);
