import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Forward from "~/assets/images/components/General/ForwardNoShadow.svg"

class History extends Component {
  constructor(props) {
    super(props);
    if (!window.apiToken) {this.props.history.push("/login");}
    document.getElementById("loading_curtain").classList.remove("d-none");
    this.state = {
      records :[],
      has_mount: false
    };
  }

  componentDidMount() {
    window.axios
      .get(`/api/history`)
      .then((response) => {
        this.setState({
          records: response.data,
          has_mount: true,
        });
        document.getElementById("loading_curtain").classList.add("d-none");
      })
      .catch((errors) => {
        document.getElementById("loading_curtain").classList.add("d-none");
        this.props.history.push("/index");
      });
  }
  render() {

      const rendered_record = this.state.records.map((record, index) => {
        return (
          <ListItem key={record.id} button style={{ backgroundColor: index % 2 ? "#242634" : "#35394C", textDecoration: "none", color: "white" }}>
            <Grid container alignItems="center">
              <Grid item xs={1.5} alignItems="center">
                <img src={record.game_icon} width="100%" />
              </Grid>
              <Grid item xs={4} container alignItems="center">
                <ListItemText>
                  <span style={{ marginLeft: "1vw", display: "flex", alignItems: "center" }}>
                    {record[`reward_quantity_${this.props.i18n.language}`]}
                    <img src={record.reward_icon} style={{ marginLeft: "1vw", maxHeight: "1.5em", verticalAlign: "middle" }} />
                  </span>
                </ListItemText>
              </Grid>
              <Grid item xs={2.3}>
                <ListItemText>{record[`date_${this.props.i18n.language}`]}</ListItemText>
              </Grid>
              <Grid item xs={3}>
                <ListItemText>
                  <p style={{ textAlign: "center" }}>{record[`rank_${this.props.i18n.language}`]}</p>
                </ListItemText>
              </Grid>
              <Grid item xs={1.2} display="flex" alignItems="center">
                <Link to={record.link} style={{ display: "flex" }}>
                  <img src={Forward} width="100%" height="100%" />
                </Link>
              </Grid>
            </Grid>
          </ListItem>
        );
      });
    if (!this.state.has_mount) {
      return <></>;
    }

    if (this.state.records.length == 0) {
      return (
        <List>
          <ListItem style={{ backgroundColor: "#242634" }}>
            <ListItemText
              primary={
                <Typography variant="body" style={{ color: "#FFF" }}>
                  {this.props.i18n.t("match_history.you_have_no_match_record")}
                </Typography>
              }
            />
          </ListItem>
        </List>
      );
    }

    return (
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item md={10} lg={6}>
          <List>
            {rendered_record}
          </List>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(History);
