import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import Grid from "@mui/material/Grid";
import { TextField } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import GoogleIcon from "@mui/icons-material/Google";
import { BsDiscord } from "react-icons/bs";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import face1 from "~/assets/images/components/Dummy/HeadIcon/face_1.png";
import face2 from "~/assets/images/components/Dummy/HeadIcon/face_2.png";
import face3 from "~/assets/images/components/Dummy/HeadIcon/face_3.png";
import { toast } from "react-toastify";

const theme = createTheme({
  palette: {
    primary: {
      light: "#cccccc",
      main: "#ffffff",
      dark: "#868686",
      contrastText: "#000",
    },
    secondary: {
      light: "#5d606f",
      main: "#35394C",
      dark: "#252735",
      contrastText: "#fff",
    },
  },
});

const avatar = {
  1:face1,
  2:face2,
  3:face3,
}

class register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gender: 1,
      email: "",
      nickname: "",
      first_name: "",
      last_name: "",
      password: "",
      password_confirmation: "",
      "g-recaptcha-response": "",
      errors: {},
    };
    this.handleUserDataInputChange = this.handleUserDataInputChange.bind(this);
    this.handleCaptcha = this.handleCaptcha.bind(this);
    this.register = this.register.bind(this);
    this.change_lang = this.change_lang.bind(this);
  }

  change_lang() {
    const { t, i18n } = this.props;
    switch (i18n.language) {
      case "eng":
        window.localStorage.setItem("i18nextLng", "cht");
        window.axios.defaults.headers.common["X-localization"] = "cht";
        i18n.changeLanguage("cht");
        break;
      case "cht":
      default:
        window.localStorage.setItem("i18nextLng", "eng");
        window.axios.defaults.headers.common["X-localization"] = "eng";
        i18n.changeLanguage("eng");
        break;
    }
  }

  handleUserDataInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleCaptcha(value) {
    this.setState({"g-recaptcha-response": value});
  }

  register() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios
      .post(`/api/user/register`, this.state)
      .then((response) => {
        document.getElementById("loading_curtain").classList.add("d-none");
        switch (response.status) {
          case 200:
            window.sessionStorage.setItem("register_email", this.state.email);
            this.props.history.push("/verify");
            break;
        }
      })
      .catch((errors) => {
        document.getElementById("loading_curtain").classList.add("d-none");
        switch (errors.response.status) {
          case 422:
            this.setState({ errors: errors.response.data.errors });
            break;
        }
      });
  }

  getError(name){
    return this.state.errors[name].join('\n');
  }

  render() {
    return (
      <form>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid container height="5vh">
            <Grid item xs={8}></Grid>
            <Grid item xs={2} container justifyContent="center" alignItems="center" textAlign="center">
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Typography variant="body2" color="white" style={{ float: "right" }}>
                  {this.props.i18n.t("register.login")}
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={2} container alignItems="center" justifyContent="center" textAlign="center">
              <LanguageIcon style={{ color: "white" }} onClick={this.change_lang} />
            </Grid>
          </Grid>
          <Grid container item xs={12} marginTop="20px" justifyContent="center" alignItems="center">
            <Typography variant="h6" color="white" textAlign="center">
              {this.props.i18n.t("register.header1")}
              <br />
              {this.props.i18n.t("register.header2")}
            </Typography>
          </Grid>
          <Grid item xs={7.5} lg={6}>
            <ThemeProvider theme={theme}>
              <div
                style={{
                  backgroundColor: "#35394C",
                  width: "100%",
                  marginBottom: "10px",
                }}>
                <ToggleButtonGroup color="primary" fullWidth={true} value={this.state.gender} exclusive onChange={this.handleUserDataInputChange} aria-label="text alignment">
                  <ToggleButton sx={{ color: "#c0c0c0" }} color="primary" value="1" name="gender" type="radio" selected={this.state.gender == "1"}>
                    {this.props.i18n.t("register.mr")}
                  </ToggleButton>
                  <ToggleButton sx={{ color: "#c0c0c0" }} color="primary" value="2" name="gender" type="radio" selected={this.state.gender == "2"}>
                    {this.props.i18n.t("register.ms")}
                  </ToggleButton>
                  <ToggleButton sx={{ color: "#c0c0c0" }} color="primary" value="3" name="gender" type="radio" selected={this.state.gender == "3"}>
                    {this.props.i18n.t("register.mx")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </ThemeProvider>
          </Grid>
          <Grid container item xs={3.5} lg={2} justifyContent="center" alignItems="center">
            <img src={avatar[this.state.gender]} width="100%" style={{ maxWidth: "300px" }} />
          </Grid>
          <Grid item xs={11} lg={8} marginTop="20px">
            <TextField
              required
              type="email"
              size="small"
              label={this.props.i18n.t("register.email")}
              name="email"
              onChange={(evt) => this.handleUserDataInputChange(evt)}
              value={this.state.email}
              variant="filled"
              fullWidth={true}
              style={{ background: "#35394C" }}
              inputProps={{ style: { fontFamily: "Arial", color: "white" } }}
              InputLabelProps={{
                style: { fontFamily: "Arial", color: "white" },
              }}
              {...("email" in this.state.errors && { error: true, helperText: this.getError("email") })}
            />
          </Grid>
          <Grid item xs={11} lg={8} marginTop="20px">
            <TextField
              required
              type="text"
              size="small"
              label={this.props.i18n.t("register.first_name")}
              name="first_name"
              onChange={(evt) => this.handleUserDataInputChange(evt)}
              value={this.state.first_name}
              variant="filled"
              fullWidth={true}
              style={{ background: "#35394C" }}
              inputProps={{ style: { fontFamily: "Arial", color: "white" } }}
              InputLabelProps={{
                style: { fontFamily: "Arial", color: "white" },
              }}
              {...("first_name" in this.state.errors && { error: true, helperText: this.getError("first_name") })}
            />
          </Grid>
          <Grid item xs={11} lg={8} marginTop="20px">
            <TextField
              required
              type="text"
              size="small"
              label={this.props.i18n.t("register.last_name")}
              name="last_name"
              onChange={(evt) => this.handleUserDataInputChange(evt)}
              value={this.state.last_name}
              variant="filled"
              fullWidth={true}
              style={{ background: "#35394C" }}
              inputProps={{ style: { fontFamily: "Arial", color: "white" } }}
              InputLabelProps={{
                style: { fontFamily: "Arial", color: "white" },
              }}
              {...("last_name" in this.state.errors && { error: true, helperText: this.getError("last_name") })}
            />
          </Grid>
          <Grid item xs={11} lg={8} marginTop="20px">
            <TextField
              required
              type="text"
              size="small"
              label={this.props.i18n.t("register.nickname")}
              name="nickname"
              onChange={(evt) => this.handleUserDataInputChange(evt)}
              value={this.state.nickname}
              variant="filled"
              fullWidth={true}
              style={{ background: "#35394C" }}
              inputProps={{ style: { fontFamily: "Arial", color: "white" } }}
              InputLabelProps={{
                style: { fontFamily: "Arial", color: "white" },
              }}
              {...("nickname" in this.state.errors && { error: true, helperText: this.getError("nickname") })}
            />
          </Grid>
          <Grid item xs={11} lg={8} marginTop="20px">
            <Tooltip title={this.props.i18n.t("register.password_hint")} arrow placement="top">
              <TextField
                required
                type="password"
                autoComplete="new-password"
                size="small"
                label={this.props.i18n.t("register.password")}
                name="password"
                onChange={(evt) => this.handleUserDataInputChange(evt)}
                value={this.state.password}
                variant="filled"
                fullWidth={true}
                style={{ background: "#35394C" }}
                inputProps={{ style: { fontFamily: "Arial", color: "white" } }}
                InputLabelProps={{
                  style: { fontFamily: "Arial", color: "white" },
                }}
                {...("password" in this.state.errors && { error: true, helperText: this.getError("password") })}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={11} lg={8} marginTop="20px">
            <TextField
              required
              type="password"
              size="small"
              label={this.props.i18n.t("register.confirm_password")}
              name="password_confirmation"
              onChange={(evt) => this.handleUserDataInputChange(evt)}
              value={this.state.password_confirmation}
              variant="filled"
              fullWidth={true}
              style={{ background: "#35394C" }}
              inputProps={{ style: { fontFamily: "Arial", color: "white" } }}
              InputLabelProps={{
                style: { fontFamily: "Arial", color: "white" },
              }}
              {...("password_confirmation" in this.state.errors && { error: true, helperText: this.getError("password_confirmation") })}
            />
          </Grid>
          <Grid item xs={11} lg={8} marginTop="20px" display="flex" justifyContent="center">
            <ReCAPTCHA sitekey="6LcOmfAdAAAAABd8sqDzmADsxVZ04sZ83iy9GTex" theme="dark" onChange={this.handleCaptcha} />
          </Grid>
          <Grid item xs={11} lg={8} marginTop="20px" display="flex" justifyContent="space-evenly">
            <Button variant="outlined" style={{ backgroundColor: "purple", color: "white", borderColor: "white" }} onClick={this.register}>
              {this.props.i18n.t("register.register")}
            </Button>
            <Button href="/login/google" target="_parent" variant="outlined" style={{ backgroundColor: "#4B80DF", color: "white", borderColor: "white" }} startIcon={<GoogleIcon />}>
              {this.props.i18n.t("register.sign_in_with_google")}
            </Button>
            <Button href="/login/discord" target="_parent" variant="outlined" style={{ backgroundColor: "#7289da", color: "white", borderColor: "white" }} startIcon={<BsDiscord />}>
              {this.props.i18n.t("register.sign_in_with_discord")}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withTranslation()(register);
