import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import GameLobby from "~/assets/images/components/GameChooserButton/GameLobby.svg";

function GameChooserButton (props)
{
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const handleLink = () => {
    window.Sound_Success.play();
    history.push("/game/all");
  };
  return (
    <img
      src={GameLobby}
      id="GameSelector"
      data-target="nav_btn_shop"
      data-content={t("tutorial.game_selector")}
      onClick={handleLink}
      style={{
        width: "2.5em",
        position: "fixed",
        zIndex: 3,
        bottom: "25vh",
        left: 0,
      }}
    />
  );
}

export default GameChooserButton;
