import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { toast } from "react-toastify";

const linkdata = [
  { linkName: "Language selection", link: "#" },
  { linkName: "Password Setting", link: "/profile" },
];
class Setting extends Component {
  constructor(props) {
    super(props);
    if (!window.apiToken) {this.props.history.push("/login");}
    this.change_lang = this.change_lang.bind(this);
    this.reset_send = this.reset_send.bind(this);
  }

  change_lang() {
    switch (this.props.i18n.language) {
      case "eng":
        window.localStorage.setItem("i18nextLng", "cht");
        window.axios.defaults.headers.common["X-localization"] = "cht";
        this.props.i18n.changeLanguage("cht");
        break;
      case "cht":
      default:
        window.localStorage.setItem("i18nextLng", "eng");
        window.axios.defaults.headers.common["X-localization"] = "eng";
        this.props.i18n.changeLanguage("eng");
        break;
    }
  }

  reset_send() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios.post(`/api/password/forget`, { email: window.sessionStorage.getItem("email") })
      .then((response) => {
        document.getElementById("loading_curtain").classList.add("d-none");
        window.Sound_TwinkleAlert.play();
        toast.success(this.props.i18n.t("forget_password.success_message"), {
          containerId: "Toast_color_flip",
          autoClose: 3000,
        });
      });
  };

  render() {
    return (
      // <List>
      //   <ListItem component={Link} to={text.link} style={{ backgroundColor: index % 2 === 0 ? "#242634" : "#35394C", height: "10vh" }}>
      //     <ListItemText primary={text.linkName} sx={{ color: "white" }}></ListItemText>
      //     <KeyboardArrowRightIcon sx={{ color: "white" }} />
      //   </ListItem>
      // </List>
      <List>
        <ListItem onClick={this.change_lang} style={{ backgroundColor: "#35394C", color: "white", height: "10vh" }}>
          <ListItemText primary={this.props.i18n.t("settings.change_language")} />
          <KeyboardArrowRightIcon />
        </ListItem>
        <ListItem onClick={this.reset_send} style={{ backgroundColor: "#35394C", color: "white", height: "10vh" }}>
          <ListItemText primary={this.props.i18n.t("settings.password_setting")} />
          <KeyboardArrowRightIcon />
        </ListItem>
        {/* <ListItem component={Link} to="/profile" style={{ backgroundColor: "#35394C", color: "white", height: "10vh" }}>
          <ListItemText primary={this.props.i18n.t("settings.password_setting")} />
          <KeyboardArrowRightIcon />
        </ListItem> */}
      </List>
    );
  }
}
export default withTranslation()(Setting);