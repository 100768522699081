import React, { Component, useState } from "react";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

class FAQ extends Component{
  constructor(props) {
    super(props);
    this.state = { id: null, content: []};
  }

  componentDidMount() {
    window.axios.get(`/api/faq`).then((response) => {
      this.setState(response.data);
    });
  }

  render() {
    return (
    <List sx={{ height: "100%", bgcolor:"#242634" }} >
      {this.state.content.map((item, index) => (
        <>
          <ListItemButton key={index} bgcolor="#242634" onClick={() => {this.setState({id: this.state.id == index ? null : index});}}>
            <ListItemText primary={item.question} style={{color:"white"}} />
            {index === this.state.id ? <ExpandLess sx={{color:"white"}}/> : <ExpandMore sx={{color:"white"}}/>}
          </ListItemButton>
          <Collapse in={index === this.state.id} timeout="auto" unmountOnExit>
            <Typography style={{ display: "inline-block", marginLeft: "4%", color:"white" }}>
              {item.answer}
            </Typography>
          </Collapse>
        </>
      ))}
    </List>
    );
  }
}

export default FAQ;