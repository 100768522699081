import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Key from "~/assets/images/components/Auth/Register/Key.svg";

class VerifySuccess extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12} height="5vh" display="flex" alignItems="center"></Grid>
        <Grid item xs={10} height="18vh" display="flex" flexDirection="column" justifyContent="space-around" alignItems="center">
          <Typography variant="h6" color="white" textAlign="center">
            {this.props.i18n.t("verify_success.verified_notice")}
          </Typography>
        </Grid>
        <Grid item xs={12} height="27vh" display="flex" justifyContent="center" alignItems="center">
          <img src={Key} width="50%" />
        </Grid>
        <Grid item xs={6} height="10vh" display="flex" justifyContent="center" alignItems="center">
          <Button variant="outlined" component={Link} to={window.apiToken ? "/index" : "/login"} style={{ width: "100%", backgroundColor: "#5C2D80", color: "white", borderColor: "white" }}>
            {this.props.i18n.t(`verify_success.${window.apiToken ? "play_now" : "sign_in_now"}`)}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(VerifySuccess);
