import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import MobileDetect from "mobile-detect";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select, { components } from "react-select";
import { toast } from "react-toastify";

import GameDetail from "~/components/Game/GameDetail";
import GrandBarCht from "~/assets/images/components/GameS/GrandBarCht.svg";
import GrandBarEng from "~/assets/images/components/GameS/GrandBarEng.svg";
import EpicBarCht from "~/assets/images/components/GameS/EpicBarCht.svg";
import EpicBarEng from "~/assets/images/components/GameS/EpicBarEng.svg";
import HeroBarCht from "~/assets/images/components/GameS/HeroBarCht.svg";
import HeroBarEng from "~/assets/images/components/GameS/HeroBarEng.svg";
import WowBarCht from "~/assets/images/components/GameS/WowBarCht.svg";
import WowBarEng from "~/assets/images/components/GameS/WowBarEng.svg";
import BonusBarCht from "~/assets/images/components/GameS/BonusBarCht.svg";
import BonusBarEng from "~/assets/images/components/GameS/BonusBarEng.svg";
import Forward from "~/assets/images/components/General/Forward.svg";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const md = new MobileDetect(navigator.userAgent);
const ScoreBG = [
  { cht:GrandBarCht, eng:GrandBarEng },
  { cht:EpicBarCht, eng:EpicBarEng },
  { cht:HeroBarCht, eng:HeroBarEng },
  { cht:WowBarCht, eng:WowBarEng },
  { cht:BonusBarCht, eng:BonusBarEng }
];

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropUpIcon />
    </components.DropdownIndicator>
  );
};

class GameSWaitingRoom extends Component {
  constructor(props) {
    if (!window.apiToken) {
      props.history.push("/login");
    }
    document.getElementById("loading_curtain").classList.remove("d-none");
    super(props);
    this.state = {
      scoreTable: [],
      RewardIcon: null,
      encrypt: null,
      gameData: [{ src: null, name: null, link: null, rule: null }],
      costs: [{ value: null, label: null }],
      selectedOption: null,
    };
    // This binding is necessary to make `this` work in the callback
    this.handleChange = this.handleChange.bind(this);
    this.joinGame = this.joinGame.bind(this);
  }

  componentDidMount() {
    window.axios.get(`/api/game/${this.props.match.params.id}/s?cost_list&payout=${this.props.match.params.cost_id}`).then((response) => {
      var data = [];
      Object.entries(response.data.cost_list).forEach(([i, row]) => {
        data.push({
          value: row.value,
          label: (
            <div>
              &#129045;
              {this.props.i18n.t("s_game.entry_fee")}: {row.quantity}
              <img src={row.img} className="VAssetIcon" />
            </div>
          ),
        });
      });
      this.setState({
        scoreTable: response.data.payout.table,
        RewardIcon: response.data.payout.RewardIcon,
        encrypt: response.data.payout.e,
        costs: data,
        selectedOption: data.find((row) => {
          return row.value == this.props.match.params.cost_id;
        }),
      });
      document.getElementById("loading_curtain").classList.add("d-none");
    });
  }
  refreshPayout = (cost_id) => {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios
      .get(`/api/game/${this.props.match.params.id}/s?payout=${cost_id}`)
      .then((response) => {
        this.setState({
          scoreTable: response.data.payout.table,
          RewardIcon: response.data.payout.RewardIcon,
          encrypt: response.data.payout.e,
        });
        document.getElementById("loading_curtain").classList.add("d-none");
      });
  };

  joinGame() {
    window.Sound_Success.play();
    if (this.state.encrypt) {
      document.getElementById("loading_curtain").classList.remove("d-none");
      window.axios
        .post(`/api/game/s/join`, {
          id: this.state.selectedOption.value,
          e: this.state.encrypt,
        })
        .then((response) => {
          if (response.data.status) {
            document.getElementById("loading_curtain").classList.add("d-none");
            window.location.href = ["iOS", "iPadOS"].includes(md.os()) ? response.data.ios_url : response.data.android_url;
          }
        });
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.refreshPayout(selectedOption.value);
  };

  render() {
    const { selectedOption } = this.state;
    if (this.state.scoreTable.length == 0) {
      return <></>
    }
    return (
      <>
        <GameDetail gameId={this.props.match.params.id} modeId="s" />
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} lg={4} container justifyContent="center" direction="row">
            {this.state.scoreTable.map((ScoreData, index) => (
              <Grid
                item
                xs={12}
                key={index}
                container
                height="10vh"
                justifyContent="center"
                alignItems="center"
                style={{
                  backgroundColor: "rgba(255,255,255,0.9)",
                  backgroundImage: `url(${ScoreBG[index][this.props.i18n.language]})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderTop: "#242634 solid 6px",
                }}>
                <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="subtitle1" color="white" style={{ fontWeight: 900, lineHeight: "inherit" }} display="inline" margin="0">
                    {this.props.i18n.t("s_game.score")}:{ScoreData.Score}
                    <br />
                    {this.props.i18n.t("s_game.reward")}:{ScoreData.Puzzle}
                    <img src={this.state.RewardIcon} className="VAssetIcon" />
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ textAlign: "center" }}></Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={3} lg={4} height="5vh" />
          <Grid item xs={7} lg={4} height="5vh" style={{ textAlign: "center", position: "relative", zIndex: 2 }}>
            <Typography
              variant="h6"
              style={{
                textAlign: "center",
                position: "relative",
                zIndex: 2,
              }}>
              <Select
                value={selectedOption}
                onChange={this.handleChange}
                onMenuOpen={() => {
                  window.Sound_Spread.play();
                }}
                onMenuClose={() => {
                  window.Sound_Spread.play();
                }}
                options={this.state.costs}
                isSearchable={false}
                defaultValue={this.state.costs[0]}
                menuPlacement="top"
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                styles={{ backgroundColor: "red" }}
              />
            </Typography>
          </Grid>
          <Grid item xs={1} lg={0.5} height="5vh" style={{ textAlign: "center", position: "relative", zIndex: 2 }}>
            <img
              onClick={this.joinGame}
              src={Forward}
              width="150%"
              style={{
                position: "relative",
                zIndex: 2,
                left: "0%",
              }}
            />
          </Grid>
          <Grid item lg={3.5} />
        </Grid>
      </>
    );
  }
}

export default withTranslation()(GameSWaitingRoom);
