import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import saveAvatarCht from "~/assets/images/components/Auth/Profile/SaveAvatarCht.svg";
import saveAvatarEng from "~/assets/images/components/Auth/Profile/SaveAvatarEng.svg";
import digitalStore from "~/assets/images/components/Auth/Profile/DigitalStore.svg";

const saveAvatar = { cht: saveAvatarCht, eng: saveAvatarEng };

class Avatar extends Component {
  constructor(props) {
    document.getElementById("loading_curtain").classList.remove("d-none");
    super(props);
    this.state = {
      avatar_selected: null,
      owned: [],
      unowned: [],
    };
    this.updateAvatar = this.updateAvatar.bind(this);
  }

  componentDidMount() {
    window.axios.get(`/api/user/avatar`, {cache: {maxAge: 1000}}).then((response) => {
      this.setState({
        avatar_selected: response.data.display_avatar,
        owned: response.data.owned,
        unowned: response.data.unowned,
      });
      document.getElementById("loading_curtain").classList.add("d-none");
    });
  }

  updateAvatar() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios.post(`/api/user/avatar`, {avatar_id:this.state.avatar_selected}).then((response) => {
      window.sessionStorage.setItem("profile_pic", response.data.profile_pic);
      document.getElementById("loading_curtain").classList.add("d-none");
      window.Sound_TwinkleAlert.play();
      toast.success(this.props.i18n.t("avatar.success_message"), {
        containerId: "Toast_color_flip",
        autoClose: 3000,
      });
    });
  }

  render() {
    return (
      <Grid container alignItems="center" justifyContent="center" bgcolor="#242634">
        <Grid item xs={12} md={10} lg={8} container>
          {this.state.owned.map((avatar) => {
            return (
              <Grid key={avatar.id} item xs={4} md={3} lg={2} display="flex" alignItems="center" justifyContent="center" bgcolor={this.state.avatar_selected == avatar.id ? "rgba(255,255,255,0.5)" : "initial"}>
                <img src={avatar.img} onClick={() => this.setState({ avatar_selected: avatar.id })} className="img-fluid p-1" />
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={4} md={3} lg={2}>
          <img src={saveAvatar[this.props.i18n.language]} width="100%" onClick={this.updateAvatar} />
        </Grid>
        {/* <Grid item xs={11}>
          <img src={digitalStore} width="100%" />
        </Grid>
        <Grid item xs={12} container alignItems="center" justifyContent="center">
          {this.state.unowned.map((avatar) => {
            return (
              <Grid key={avatar.id} item xs={4} md={2} lg={1} >
                <img src={avatar.img} width="85%" />
              </Grid>
            );
          })}
        </Grid> */}
      </Grid>
    );
  }
}

export default withTranslation()(Avatar);
