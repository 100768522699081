import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { RWebShare } from "react-web-share";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import PoweredBy from "~/assets/images/components/General/PoweredByPuzzleChase.svg";
import referimg from "~/assets/images/components/ReferalPopup/Referfriend.svg";

const contentStyle = {
  marginTop: '20%',
  background: '#242634',
  width: "90%",
  height: "60%",
  borderRadius: "5%",
};

class ReferalPopup extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Popup open={this.props.open} modal nested closeOnDocumentClick onClose={this.props.setOpen} {...{ contentStyle }}>
        {(close) => (
          <div className="bg-transparent modal modal-content border-0">
            <button className="close" onClick={close}></button>
            <Grid item xs={12}>
              <Typography variant="body2" component="div" color="primary" textAlign="center">
                Refer friends & earn up to 500 puzzles
              </Typography>
              <Typography variant="caption" component="div" color="primary" textAlign="center">
                Referal Code
              </Typography>

              <Grid item xs={12} height="38vh">
                <div className="video-responsive">
                  <Grid item xs={12} textAlign="center">
                    <RWebShare
                      data={{
                        text: 'Copy "ILOVEREESPUZZLES" and go to our official website',
                        url: "https://puzzlegame2021.netlify.app/p",
                        title: "Share With your friends!",
                      }}
                      onClick={() => console.log("shared successfully!")}>
                      <Button variant="outlined">ILOVEREESPUZZLES</Button>
                    </RWebShare>
                  </Grid>
                  <Typography variant="body2" component="div" color="primary" textAlign="center">
                    <img src={referimg} width="70%" alt="referimage" />
                    <img src={PoweredBy} width="40%" />
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </Popup>
    );
  }
}

export default ReferalPopup;
