import React, { Component } from "react";
import ReactPlayer from "react-player";
import "~/assets/styles/react-player.css"
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";

class MissionDetail extends Component {
  constructor(props) {
    super(props);
    if (!window.apiToken) {
      this.props.history.push("/login");
    }
    this.state = {
      link         : null,
      url          : null,
      hash         : null,
      duration     : null,
      type         : null,
      claim_disable: true,
      duration     : 0,
      c            : false,
    };
    this.startTimer    = this.startTimer.bind(this);
    this.pauseTimer    = this.pauseTimer.bind(this);
    this.getReward     = this.getReward.bind(this);
    this.contentSwitch = this.contentSwitch.bind(this);
  }

  componentDidMount() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios.get(`/api/mission/detail/${this.props.match.params.id}`).then((response) => {
      this.setState(response.data);
      document.getElementById("loading_curtain").classList.add("d-none");
    });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  startTimer() {
    this.timer = setInterval(() => this.tick(), 1000);
  }

  pauseTimer() {
    clearInterval(this.timer);
  }

  tick() {
    if (this.state.duration > 0) {
      this.setState((prevState) => ({ duration: prevState.duration - 1 }));
    } else {
      clearInterval(this.timer);
      this.setState({
        claim_disable: false,
        duration: 0,
      });
    }
  }

  getReward() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.Sound_ShortSuccess.play();
    window.axios
      .post(`/api/mission/reward/${this.props.match.params.id}`, {hash: this.state.hash})
      .then((response) => {
        window.updateWallet();
        var missions = JSON.parse(window.sessionStorage.getItem("mission"));
        var target_mission = missions.data.filter((mission) => mission.id == this.props.match.params.id)[0];
        target_mission.remain_rewards--;
        var new_missions = missions.data.filter((mission) => mission.id != this.props.match.params.id);
        new_missions.push(target_mission);
        new_missions.sort((a, b) => a.id - b.id);
        window.sessionStorage.setItem("mission", JSON.stringify({ ...{ data: new_missions }, ...{ updated_at: Date.now() } }));
        document.getElementById("loading_curtain").classList.add("d-none");
        window.Sound_TwinkleAlert.play();
        toast.success(this.props.i18n.t("mission.success"), {
          containerId: "Toast_color_flip",
          autoClose: 3000,
        });
        this.props.history.replace("/mission", { state: { deleted: false } });
      })
      .catch((errors) => {
        this.props.history.replace("/mission", { state: { deleted: false } });
      });
  }

  contentSwitch() {
    switch (this.state.type) {
      case "image":
        if (this.state.link) {
          return <a target="_blank" href={`${this.state.link}`}><img src={`${this.state.url}`} className="img-fluid" onLoad={this.startTimer}/></a>;
        } else {
          return <img src={`${this.state.url}`} className="img-fluid" onLoad={this.startTimer}/>;
        }
        break;
      case "video":
        return <ReactPlayer className="react-player" url={this.state.url} playing={true} loop={true} muted={this.state.m} controls={this.state.c} onPlay={this.startTimer} onPause={this.pauseTimer} config={{
        youtube: {
             playerVars: { modestbranding: 1 },
             embedOptions: { donotallowfullscreen: 1 }
        }
    }}/>;
        break;
      default:
        return (
          <Typography variant="body2" color="white" align="center">
            {this.props.i18n.t("mission.coming_soon")}
          </Typography>
        );
        break;
    }
  }

  render() {
    return (
      <Grid container display="flex" alignItems="center" justifyContent="center">
        <Grid item xs={12} lg={8} display="flex" alignItems="center" justifyContent="center" color="white">
          {this.contentSwitch()}
        </Grid>
        <Grid container item xs={12} lg={8} alignItems="center" justifyContent="center" color="white">
          <Button variant="outlined" style={{ color: "white", borderColor: "white" }} disabled={this.state.claim_disable} onClick={this.getReward}>
            {this.state.claim_disable ? this.props.i18n.t("general.wait", { second: this.state.duration }) : this.props.i18n.t("mission.get_reward")}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(MissionDetail);
