import React from "react";
import { useTranslation } from "react-i18next";
import { useCache } from "react-use-cache";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import RuleCht from "~/assets/images/components/GameLobby/RuleCht.svg";
import RuleEng from "~/assets/images/components/GameLobby/RuleEng.svg";
import TutorialCht from "~/assets/images/components/GameLobby/TutorialCht.svg";
import TutorialEng from "~/assets/images/components/GameLobby/TutorialEng.svg";
import DownloadCht from "~/assets/images/components/GameLobby/DownloadCht.svg";
import DownloadEng from "~/assets/images/components/GameLobby/DownloadEng.svg";
import ToWebCht from "~/assets/images/components/GameLobby/ToWebCht.svg";
import ToWebEng from "~/assets/images/components/GameLobby/ToWebEng.svg";
import GameHelpPopup from "~/components/Game/GameHelpPopup";
import GameRulePopup from "~/components/Game/GameRulePopup";

export default (props) => {
  const { t, i18n } = useTranslation();
  const Tutorial = { cht: TutorialCht, eng: TutorialEng };
  const Download = { cht: DownloadCht, eng: DownloadEng };
  const ToWeb = { cht: ToWebCht, eng: ToWebEng };
  const Rule = { cht: RuleCht, eng: RuleEng };
  var finalRule = null;
  var subtitle = t("game_detail.subtitle");
  const [rule, setRule] = React.useState(false);
  const [help, setHelp] = React.useState(false);
  const { isFetching, data: gameData } = useCache(() => window.axios.get(`/api/game/detail/${props.gameId}`), `game_detail_${props.gameId}`);
  if (isFetching || !props.gameId) {
    return (<></>);
  }
  if (props.modeId) {
    switch (props.modeId) {
      case "s":
        finalRule = gameData.data[`s_rule_${i18n.language}`];
        subtitle = t("game_detail.subtitle_s");
        break;
      case "m":
        finalRule = gameData.data[`m_rule_${i18n.language}`];
        subtitle = t("game_detail.subtitle_m");
        break;
      case "v":
        finalRule = gameData.data[`v_rule_${i18n.language}`];
        subtitle = t("game_detail.subtitle_v");
        break;
      default:
        finalRule = null;
        subtitle = t("game_detail.subtitle");
        break;
    }
  }
    return (
      <div className="bg-secondary py-2 text-white">
        <Toolbar>
          <Grid container spacing={1}>
            <Grid item xs={2} md={1}>
              <img src={gameData.data.src} width="100%" style={{maxWidth: "5rem"}} />
            </Grid>
            <Grid item xs={7} md={9.5} lg={10}>
              <Typography variant="body2" component="div" sx={{ flexGrow: 1, textAlign: "left", margin: 0, marginLeft: "2vh" }}>
                {gameData.data[`name_${i18n.language}`]}
                <Typography variant="caption" component="div" sx={{ flexGrow: 1, textAlign: "left" }}>
                  {subtitle}
                </Typography>
              </Typography>
            </Grid>
            {props.modeId ? (
              <>
                <Grid item xs={1.5} md={0.75} lg={0.5} className="d-flex">
                  <img src={Rule[i18n.language]} width="100%" onClick={() => {setRule(finalRule);}} style={{maxHeight: "5rem"}} />
                  <GameRulePopup name={gameData.data[`name_${i18n.language}`]} title={t("game_detail.game_rule")} rule={rule} setOpen={() => {setRule(false);}}/>
                </Grid>
                <Grid item xs={1.5} md={0.75} lg={0.5} className="d-flex">
                  {props.modeId == "m" ? (
                  <img id="rule_btn" data-content={t("tutorial.m_mode.rules")} data-target="start_btn" src={Tutorial[i18n.language]} width="100%" style={{maxHeight: "5rem"}} onClick={() => {tutorial("rankboard");}}/>
                  ) : (
                  <>
                    <img src={Tutorial[i18n.language]} width="100%" onClick={() => {setHelp(props.modeId);}} style={{maxHeight: "5rem"}}/>
                    <GameHelpPopup mode={help} setMode={() => {setHelp(false);}} />
                  </>
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={1.5} md={0.75} lg={0.5} className="d-flex">
                  {/* {gameData.data.pc_link && (
                    <a href={gameData.data.pc_link} target="_parent" className="d-flex align-items-center justify-content-center">
                      <img src={ToWeb[i18n.language]} width="100%" style={{maxHeight: "5rem"}}/>
                    </a>
                  )} */}
                  <img src={ToWeb[i18n.language]} width="100%" style={{maxHeight: "5rem"}} onClick={() => {tutorial("mode_slider");}}/>
                </Grid>
                <Grid item xs={1.5} md={0.75} lg={0.5} className="d-flex">
                  <a href={gameData.data.link} target="_blank" className="d-flex align-items-center justify-content-center">
                    <img src={Download[i18n.language]} width="100%" style={{maxHeight: "5rem"}} />
                  </a>
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </div>
    );
}
