import React from 'react';
import Marquee from "react-fast-marquee";
import "~/assets/styles/ScrollText.css";
export default function scrolltext(props) {
  return (
    <div
      style={{
        width: "100%",
        whiteSpace: "nowrap",
        alignItems: "center",
        color: "white",
        backgroundColor: "#727172",
      }}>
      <Marquee direction="left" gradient={false}>
        {props.data}
      </Marquee>
    </div>
  );
}
