// Import Plugin
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from '@mui/material/ListItemButton';
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import LanguageIcon from "@mui/icons-material/Language";
import Collapse from '@mui/material/Collapse';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from '@mui/icons-material/Logout';
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
// Import material
import Close from "~/assets/images/components/HamburgerMenu/CloseNoShadow.svg";
import coderedeem from "~/assets/images/components/HamburgerMenu/CodeRedeem.svg";
import Hamburgicon from "~/assets/images/components/HamburgerMenu/HamburgerIconNoShadow.svg";
import LogoutSign from "~/assets/images/components/HamburgerMenu/Logout.svg";

const Bottomlinkdata = [
];

const useStyles = makeStyles({
  paper: {
    background: "#242634",
  },
});

function toggleFullscreen() {
  let elem = document.body;

  if (!document.fullscreenElement) {
    elem.requestFullscreen().catch((err) => {
      console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
    });
  } else {
    document.exitFullscreen();
  }
}

function HamburgerMenu() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const Toplinkdata = [
    { id: "1", name: t("menu.profile"), link: `/profile`, auth: true },
    // { id: "2", name: "SETTING", link: `/setting`, auth: true },
    { id: "2", name: t("menu.setting"), link: `/setting`, auth: true },
    { id: "3", name: t("menu.event"), link: "/events", auth: false },
    // { id: "3", name: "EVENTS", link: "/Events", auth: false },
    { id: "4", name: t("menu.buy_puzzle"), link: "/puzzle/package", auth: true },
    { id: "5", name: t("menu.transaction_history"), link: "/transaction", auth: true },
    /* {
      id: "6",
      name: t("menu.help"),
      link: "",
      child: [
        // { id: "6", name: t("menu.reward_status"), link: `#`, auth: true },
        // { id: "7", name: t("menu.player_support_guide"), link: `#`, auth: false },
        // { id: "9", name: t("menu.privacy_policy"), link: "/help/policy", auth: false },
        // { id: "10", name: t("menu.terms_of_service"), link: "/help/tc", auth: false },
        // { id: "11", name: t("menu.about_rees"), link: "#", auth: false },
        // { id: "6", name: "Reward Status", link: `/MissionMain/RewardStatus`, auth: true },
        // { id: "7", name: "Player Support Guide", link: `/help/guide`, auth: false },
        // { id: "8", name: "General FAQ", link: "/help/faq", auth: false },
        // { id: "9", name: "Privacy Policy", link: "/help/policy", auth: false },
        // { id: "10", name: "Terms of Service", link: "/help/tc", auth: false },
        // { id: "11", name: "About REES", link: "/aboutus", auth: false },
      ],
      auth: false,
    }, */
    { id: "7", name: t("menu.customer_service"), link: "//discord.gg/97HPcHpBha", auth: false },
    { id: "8", name: t("menu.general_faq"), link: "//discord.gg/F6eaXpRmdt", auth: false },
    // { id: "13", name: t("menu.press"), link: "/index", auth: false },
  ];
  const styles = useStyles();
  const [drawer, setDrawer] = React.useState(false);
  const [number, setNumber] = React.useState(0);
  const [li, setLi] = React.useState({});

  const handleLink =(object) => {
    if (object.message) {
      alert(object.message);
    } else {
      var link = object.link;
      if (link.substr(0, 2) == "//") {
        window.top.location.href = object.link;
      } else {
        history.push(link);
      }
    }
  }
  const handleLi = (name) => {
    setLi({
      ...li,
      [name]: !li[name],
    });
  };
  const MenuItem = ({ menu }) => {
    return menu.map((item, index) => {
      if (!item.hasOwnProperty("child")) {
        return (
          (window.apiToken || (!item.auth && !window.apiToken)) && (
            <ListItemButton
              key={item.id}
              sx={{ pl: 4 }}
              style={{ textDecoration: "none", color: "white" }}
              onClick={() => {
                handleLink(item);
                setDrawer(!drawer);
              }}>
              <ListItemText primary={item.name} />
              <KeyboardArrowRightIcon />
            </ListItemButton>
          )
        );
      } else {
        return (
          (window.apiToken || (!item.auth && !window.apiToken)) && (
            <React.Fragment key={item.id}>
              <ListItemButton
                onClick={() => {
                  handleLi(item.id);
                }}
                sx={{ pl: 4 }}
                style={{ color: "white" }}>
                <ListItemText primary={item.name} />
                {li[item.id] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={li[item.id]} timeout="auto" unmountOnExit>
                <List disablePadding>
                  <MenuItem menu={item.child} />
                </List>
              </Collapse>
            </React.Fragment>
          )
        );
      }
    });
  };

  const ChangeLanguage = () => {
    return (
      <ListItem button component="button" sx={{ pl: 4 }} style={{ color: "white" }} onClick={changeLang}>
        <ListItemText primary={t("menu.change_language")} />
        <LanguageIcon />
      </ListItem>
    );
  };

  const FullScreen = () => {
    return (
      <ListItem button component="button" sx={{ pl: 4 }} style={{ color: "white" }} onClick={toggleFullscreen}>
        <ListItemText primary={t("menu.fullscreen")} />
        <FullscreenExitIcon />
      </ListItem>
    );
  };

  const Logout = () => {
    return (
      <ListItem button form="logout" type="submit" component="button" sx={{ pl: 4 }} style={{ color: "white" }} onClick={submitLogoutForm}>
        <ListItemText primary={t("menu.logout")} />
        <LogoutIcon/>
      </ListItem>
    );
  };

  const submitLogoutForm = () => {
    window.axios
      .post(`/api/logout`)
      .then((response) => {window.sessionStorage.clear(); window.location.replace("/index");})
  }

  const Login = () => {
    return (
      <ListItemButton component={Link} to="/login" sx={{ pl: 4 }} style={{ textDecoration: "none", color: "white" }}>
        <ListItemText primary={t("menu.login")} />
        <LoginIcon />
      </ListItemButton>
    );
  };

  const Register = () => {
    return (
      <ListItemButton component={Link} to="/register" sx={{ pl: 4 }} style={{ textDecoration: "none", color: "white" }}>
        <ListItemText primary={t("menu.register")} />
        <AppRegistrationIcon />
      </ListItemButton>
    );
  };

  function changeLang() {
    switch (i18n.language) {
      case "eng":
        window.localStorage.setItem("i18nextLng", "cht");
        window.axios.defaults.headers.common["X-localization"] = "cht";
        i18n.changeLanguage("cht");
        window.axios.get(`/api/lang/eng`)
        break;
      case "cht":
      default:
        window.localStorage.setItem("i18nextLng", "eng");
        window.axios.defaults.headers.common["X-localization"] = "eng";
        i18n.changeLanguage("eng");
        window.axios.get(`/api/lang/cht`)
        break;
    }
  }

  return (
    <>
      <img
        src={Hamburgicon}
        style={{ height: "48px" }}
        onClick={() => {
          setDrawer(!drawer);
        }}
      />
      <Drawer
        classes={{ paper: styles.paper }}
        anchor={"right"}
        open={drawer}
        onClose={() => {
          setDrawer(!drawer);
        }}>
        <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", padding: "0px" }}>
          <img
            src={Close}
            height="48px"
            onClick={() => {
              setDrawer(!drawer);
            }}
            style={{ marginTop: "5px", marginBottom: "5px" }}
          />
        </div>
        <List style={{ width: "300px", maxWidth: "70vw" }} disablePadding>
          {!window.apiToken && (
            <List
              onClick={() => {
                setDrawer(!drawer);
              }}>
              <Login />
              <Register />
            </List>
          )}
          <MenuItem menu={Toplinkdata} />
          {/* <ListItem>
            <ListItemText style={{ fontSize: "0.7em" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  fontSize: "0.7em",
                  color: "white",
                }}>
                CODE REDEEM :
                <TextField id="input-with-sx" variant="standard" />
                <img src={coderedeem} alt="coderedeem" />
              </Box>
            </ListItemText>
          </ListItem> */}
        </List>
        <div style={{ flexGrow: "1" }}></div>
        <List
          onClick={() => {
            setDrawer(!drawer);
          }}>
          <MenuItem menu={Bottomlinkdata} />
          <ChangeLanguage />
          <FullScreen />
        </List>
        {window.apiToken && (
          <List>
            <Logout />
          </List>
        )}
      </Drawer>
    </>
  );
}

export default HamburgerMenu;
