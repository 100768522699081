import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Checkbox } from "@mui/material";
import Grid from "@mui/material/Grid";
import { TextField, makeStyles } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import LoginRight from "~/assets/images/components/Auth/LoginRight.svg";
import Loginimg from "~/assets/images/components/Auth/Loginimg.svg";
import HomeButton from "~/components/HomeButton";
import Button from "@mui/material/Button";
import GoogleIcon from "@mui/icons-material/Google";
import { BsDiscord } from "react-icons/bs";
import FacebookIcon from "@mui/icons-material/Facebook";
import LanguageIcon from "@mui/icons-material/Language";

const classes = {
    color: "#FFF",
    cssUnderline: {
      "&:after": {
        borderBottomColor: "#333333",
      },
      disableUnderline: true,
      underline: {
        "&&&:before": {
          borderBottom: "none",
        },
      },
    },
  };

class SignIn extends Component {
  constructor(props) {
    super(props);
    if (window.apiToken) {
      props.history.replace("/index", { state: { deleted: false } });
    }
    this.state = {
      email: "",
      password: "",
    };
    this.handleUserDataInputChange = this.handleUserDataInputChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.change_lang = this.change_lang.bind(this);
  }

  change_lang() {
    const { t, i18n } = this.props;
    switch (i18n.language) {
      case "eng":
        window.localStorage.setItem("i18nextLng", "cht");
        window.axios.defaults.headers.common["X-localization"] = "cht";
        i18n.changeLanguage("cht");
        break;
      case "cht":
      default:
        window.localStorage.setItem("i18nextLng", "eng");
        window.axios.defaults.headers.common["X-localization"] = "eng";
        i18n.changeLanguage("eng");
        break;
    }
  }

  componentDidMount() {
    document.getElementById("loading_curtain").classList.add("d-none");
  }

  handleUserDataInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  submitForm() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios
      .post(`/api/login`, this.state)
      .then((response) => {
        window.location.replace("/index");
      })
  }

  render() {
    return (
      <>
        <HomeButton />
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid container height="5vh">
            <Grid item xs={8}></Grid>
            <Grid item xs={2} container alignItems="center" justifyContent="center" textAlign="center">
              <Link to="/register" style={{ textDecoration: "none" }}>
                <Typography variant="body2" color="white" style={{ float: "right" }}>
                  {this.props.i18n.t("login.signup")}
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={2} container alignItems="center" justifyContent="center" textAlign="center">
              <LanguageIcon style={{ color: "white" }} onClick={this.change_lang} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ paddingLeft: "3vw", paddingRight: "3vw" }} minHeight="50vh">
            <Grid item xs={6} container textAlign="center">
              <Grid item xs={12} container>
                <Typography variant="h6" color="white" textAlign="center">
                  {this.props.i18n.t("login.welcome_back")}
                </Typography>
                <TextField id="filled-multiline-flexible" name="email" label={this.props.i18n.t("login.username")} variant="filled" size="small" fullWidth={true} inputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "white" } }} onChange={this.handleUserDataInputChange} />
                <TextField id="filled-multiline-flexible" name="password" type="password" label={this.props.i18n.t("login.password")} variant="filled" size="small" fullWidth={true} inputProps={{ style: { color: "white" } }} InputLabelProps={{ style: { color: "white" } }} onChange={this.handleUserDataInputChange} />
                <Grid item xs={12} height="5vh" alignItems="center" justifyContent="center" textAlign="center">
                  <Typography color="white">
                    <Checkbox color="error" sx={{ color: "white", "&.Mui-checked": { color: "white" } }} style={{ paddingTop: "0.5vh" }} name="remember" onChange={this.handleUserDataInputChange} />
                    {this.props.i18n.t("login.remember_me")}
                  </Typography>
                </Grid>
                <Grid item xs={12} alignItems="center" justifyContent="center" textAlign="center">
                  <button onClick={this.submitForm} style={{ background: "none", border: "none" }}>
                    <img src={LoginRight} width="100%" />
                  </button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} className="d-flex justify-content-center align-items-center">
              <img src={Loginimg} className="img-fluid" />
            </Grid>
          </Grid>
          <Grid container height="25vh" alignItems="center">
            <Grid item xs={12} alignItems="center" justifyContent="center" textAlign="center">
              <Button href="/login/google" target="_parent" variant="outlined" style={{ backgroundColor: "#4B80DF", color: "white", borderColor: "white", width: "80vw" }} startIcon={<GoogleIcon />}>
                {this.props.i18n.t("login.sign_in_with_google")}
              </Button>
            </Grid>
            <Grid item xs={12} alignItems="center" justifyContent="center" textAlign="center">
              <Button href="/login/discord" target="_parent" variant="outlined" style={{ backgroundColor: "#7289da", color: "white", borderColor: "white",width: "80vw" }} startIcon={<BsDiscord />}>
                {this.props.i18n.t("login.sign_in_with_discord")}
              </Button>
            </Grid>
            {/* <Grid item xs={12} alignItems="center" justifyContent="center" textAlign="center">
            <Button href="/login/facebook" variant="outlined" style={{ backgroundColor: "#3C5A98", color: "white", borderColor: "white", width:"80vw"}} startIcon={<FacebookIcon />}>
              Sign in with Facebook
            </Button>
          </Grid> */}
          </Grid>
          <Grid item xs={12} container height="20vh">
            <Grid item xs={4.5} container height="20vh" justifyContent="center" alignItems="center">
              <Link to="/password/forget" style={{ textDecoration: "none" }}>
                <Typography color="white" variant="caption">
                  {this.props.i18n.t("login.forgot_password")}
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={7.5} container height="20vh" justifyContent="center" alignItems="center">
              <Link to="/register" style={{ textDecoration: "none" }}>
                <Typography color="white" variant="caption">
                  {this.props.i18n.t("login.signup_question")}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default withTranslation()(SignIn);

// export default function SignIn() {
// }
