import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Popup from "reactjs-popup";
import Button from "@mui/material/Button";

const contentStyle = {
  background: "#242634",
  width: "90%",
  border: "none",
  height: "auto",
  borderRadius: "2vw",
};
export default (props) => {
  const { t, i18n } = useTranslation();
  return (
    <Popup open={Object.keys(props.link).length > 0} closeOnDocumentClick={false} modal lockScroll nested {...{ contentStyle }}>
      <div className="bg-transparent modal modal-content border-0">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="body1" color="white" align="center">
              {t("payment_method.complete.title")}
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h5" color="white" align="center">
              {props.description}
            </Typography>
          </Grid>
          <Grid item xs={8} display="flex" justifyContent="center" alignItems="center" style={{ marginTop: "2vh" }}>
            <Button variant="outlined" component="a" target="_parent" href={props.link.purchase_link} style={{ width: "100%", backgroundColor: "#5C2D80", color: "white", borderColor: "white" }}>
              {t("payment_method.complete.complete")}
            </Button>
          </Grid>
          <Grid item xs={8} display="flex" justifyContent="center" alignItems="center" style={{ marginTop: "2vh" }}>
            <Button variant="outlined" component="a" target="_parent" href={props.link.remove_link} style={{ width: "100%", backgroundColor: "#FF0000", color: "white", borderColor: "white" }}>
              {t("payment_method.complete.remove")}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Popup>
  );
};
