import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const contentStyle = {
  background: "#242634",
  width: "90%",
  border: "none",
  height: "auto",
  borderRadius: "5%",
};

class GeneralPopup extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Popup open={this.props.open != null} modal closeOnDocumentClick={false} onClose={this.props.setOpen} nested {...{ contentStyle }}>
        {(close) => (
          <div className="bg-transparent modal modal-content border-0">
            <button className="close" onClick={close}></button>
            <Grid container>
              <Grid item xs={12} textAlign="center">
                <Typography color="white" variant="body1" paddingTop="5vh" paddingBottom="5vh" paddingLeft="2vw" paddingRight="2vw" whiteSpace="pre-line" textAlign="left">
                  {this.props.open}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}
      </Popup>
    );
  }
}

export default withTranslation()(GeneralPopup);
