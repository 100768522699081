import React, { Component } from "react";
import Grid from "@mui/material/Grid";

class NormalText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: null,
    };
  }

  componentDidMount() {
    window.axios.get(`/api/${this.props.match.params.link ?? this.props.link}`).then((response) => {
      this.setState({ text: { __html: response.data.content } });
    });
  }

  render() {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" bgcolor="#242634">
        <Grid item xs={11} color="white">
          <div dangerouslySetInnerHTML={this.state.text} />
        </Grid>
      </Grid>
    );
  }
}
export default NormalText;
