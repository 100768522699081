import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Gift from "~/assets/images/components/Auth/Register/CircleGift.svg";
import Morepuzzle from "~/assets/images/components/Auth/Register/Morepuzzle.svg";
import Movie from "~/assets/images/components/Auth/Register/Movie.svg";
import Forward from "~/assets/images/components/General/Forward.svg";
import PoweredBy from "~/assets/images/components/General/PoweredByPuzzleChase.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

function Intro() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const swiperRef = useRef(null);
  const nextSlide = () => {
    swiperRef.current.swiper.slides.length == swiperRef.current.swiper.activeIndex + 1 ? history.push({ pathname: "index", state: { prevPath: "intro" } }) : swiperRef.current.swiper.slideNext();
  };
  return (
    <Grid container direction="row" alignItems="center" justifyContent="center">
      <Grid item xs={12} display="flex" alignItems="center" justifyContent="end">
        <Link to="/index" variant="body2" underline="none" style={{ mt: "5vh", mr: "5vw", color: "white", textDecoration: "none", float: "right" }}>
          {t("register.skip")}
        </Link>
      </Grid>
      <Grid item xs={12} lg={6.2} display="flex" height="50vh" justifyContent="center" alignItems="center">
        <Swiper ref={swiperRef} modules={[Pagination]} pagination={true} allowTouchMove={false}>
          <SwiperSlide style={{ height: "50vh", display: "flex", placeContent: "center end", placeItems: "center", flexDirection: "column", paddingBottom: "3rem" }}>
            <img src={Morepuzzle} style={{ width: "300px", maxWidth: "70%" }} />
            <Typography variant="body1" color="white" align="center">
              {t("register.intro1")}
            </Typography>
          </SwiperSlide>
          <SwiperSlide style={{ height: "50vh", display: "flex", placeContent: "center end", placeItems: "center", flexDirection: "column", paddingBottom: "3rem" }}>
            <img src={Gift} style={{ width: "300px", maxWidth: "60%" }} />
            <Typography variant="body1" color="white" align="center">
              {t("register.intro2")}
            </Typography>
          </SwiperSlide>
          <SwiperSlide style={{ height: "50vh", display: "flex", placeContent: "center end", placeItems: "center", flexDirection: "column", paddingBottom: "3rem" }}>
            <img src={Movie} style={{ width: "300px", maxWidth: "60%" }} />
            <Typography variant="body1" color="white" align="center">
              {t("register.intro3")}
            </Typography>
          </SwiperSlide>
        </Swiper>
      </Grid>
      <Grid item xs={12} lg={6.2} height="15vh" display="flex" justifyContent="end">
        <img src={Forward} style={{ width: "20%", maxWidth: "20vw" }} onClick={nextSlide} />
      </Grid>
      <Grid item xs={12} height="22vh" display="flex" justifyContent="center" alignItems="center">
        <img src={PoweredBy} style={{ maxHeight: "13vh", width: "60vw" }} />
      </Grid>
    </Grid>
  );
}

export default Intro;
