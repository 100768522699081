import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import IconButton from "@mui/material/IconButton";
import Avatar from '@mui/material/Avatar';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import { FaPaypal, FaCreditCard } from "react-icons/fa";
import ConfirmPurchasePopup from "~/components/Wallet/ConfirmPurchasePopup";

class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.params = Object.fromEntries(new URLSearchParams(this.props.location.search));
    this.state = {
      link: {},
      description: {cht: null, eng: null},
    };
    this.purchase = this.purchase.bind(this);
  }

  purchase(payment_method) {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios
      .post(`/purchase`, { ...this.params, ...{ payment_method: payment_method } })
      .then((response) => {
        if (response.data.new_order) {
          window.location.href = response.data.purchase_url;
        } else {
          this.setState({
            link: {
              purchase_link: response.data.purchase_url,
              remove_link: response.data.cancel_url,
            },
            description: response.data.description,
          });
          document.getElementById("loading_curtain").classList.add("d-none");
        }
      });
  }

  render() {
    const data = [
      { icon: FaCreditCard, value: "labpay", title: this.props.i18n.t("payment_method.Labpay.name"), detail: this.props.i18n.t("payment_method.Labpay.description") },
      { icon: FaPaypal, value: "paypal", title: this.props.i18n.t("payment_method.PayPal.name"), detail: this.props.i18n.t("payment_method.PayPal.description") },
    ];
    return (
      <>
        <List>
          {data.map((item, index) => {
            return (
              <ListItem key={index} button onClick={() => this.purchase(item.value)} style={{ height: "20vh", backgroundColor: index % 2 == 1 ? "#242634" : "#35394C" }} secondaryAction={<ArrowForwardIosIcon style={{ textDecoration: "none", color: "#FFF" }} />}>
                <ListItemAvatar>
                  <item.icon style={{ color: "#FFF", maxHeight: "10vh", height: "auto", width: "10vw" }} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h4" style={{ color: "#FFF" }}>
                      {item.title}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="h6" style={{ color: "#FFF" }}>
                      {item.detail}
                    </Typography>
                  }
                />
              </ListItem>
            );
          })}
        </List>
        <ConfirmPurchasePopup link={this.state.link} description={this.state.description[this.props.i18n.language]} />
      </>
    );
  }
}

export default withTranslation()(PaymentMethod);
