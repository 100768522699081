import * as React from "react";
import { useTranslation } from "react-i18next";
import { useCache } from "react-use-cache";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import { useHistory } from "react-router";
import styled from "@mui/material/styles/styled";
import History from "~/assets/images/components/EndBar/LinkBar/HistoryLink.svg";
import Mission from "~/assets/images/components/EndBar/LinkBar/MissionLink.svg";
import Player from "~/assets/images/components/EndBar/LinkBar/PlayerLink.svg";
import Shop from "~/assets/images/components/EndBar/LinkBar/ShopLink.svg";
import Wallet from "~/assets/images/components/EndBar/LinkBar/WalletLink.svg";
import WordHistoryEng from "~/assets/images/components/EndBar/LinkBar/WordHistoryEng.svg";
import WordHistoryCht from "~/assets/images/components/EndBar/LinkBar/WordHistoryCht.svg";
import WordMissionEng from "~/assets/images/components/EndBar/LinkBar/WordMissionEng.svg";
import WordMissionCht from "~/assets/images/components/EndBar/LinkBar/WordMissionCht.svg";
import WordPlayerEng from "~/assets/images/components/EndBar/LinkBar/WordPlayerEng.svg";
import WordPlayerCht from "~/assets/images/components/EndBar/LinkBar/WordPlayerCht.svg";
import WordShopEng from "~/assets/images/components/EndBar/LinkBar/WordShopEng.svg";
import WordShopCht from "~/assets/images/components/EndBar/LinkBar/WordShopCht.svg";
import WordWalletEng from "~/assets/images/components/EndBar/LinkBar/WordWalletEng.svg";
import WordWalletCht from "~/assets/images/components/EndBar/LinkBar/WordWalletCht.svg";

const objects = [
  {
    id      : 0,
    category: 'game',
    image   : `${Player}`,
    link    : "/index",
    word    : { cht: {img: WordPlayerCht, height: "0.8em"}, eng: {img: WordPlayerEng, height: "0.5em"} },
  },
  {
    id      : 1,
    category: 'shop',
    image   : `${Shop}`,
    link    : "*/shop",
    word    : { cht: {img: WordShopCht, height: "0.8em"}, eng: {img: WordShopEng, height: "0.5em"} },
  },
  {
    id      : 2,
    category: 'wallet',
    image   : `${Wallet}`,
    link    : "/wallet",
    word    : { cht: {img: WordWalletCht, height: "0.8em"}, eng: {img: WordWalletEng, height: "0.5em"} },
  },
  {
    id      : 3,
    category: 'history',
    image   : `${History}`,
    link    : "/history",
    word    : { cht: {img: WordHistoryCht, height: "0.8em"}, eng: {img: WordHistoryEng, height: "0.5em"} },
  },
  {
    id      : 4,
    category: 'mission',
    image   : `${Mission}`,
    link    : "/mission",
    word    : { cht: {img: WordMissionCht, height: "0.8em"}, eng: {img: WordMissionEng, height: "0.5em"} },
  },
];

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: "10%",
    right: "10%",
    border: `1px solid #ffffff`,
    padding: "0 1px",
    backgroundColor: "red",
    color: "white",
  },
}));

const enlarge_icon = {
  width   : "100%",
  position: "relative",
  zIndex  : 1,
};
const normal_icon = {
  width     : "60%",
  position  : "relative",
  zIndex    : 1,
};

export default (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const handleLink = (object) => {
    window.Sound_GObell.play();
    var link = object.link;
    if (link.substr(0, 2) == "//") {
      window.top.location.href = link;
    } else if (link.substr(0, 2) == "*/") {
      window.location.assign(link.replace("*", ""));
    } else {
      history.push(link);
    }
  };
  return (
    <>
      <Toolbar>
        <Grid container direction="row">
          {objects.map((object, index) => (
            <Grid id={`nav_btn_${object.category}`} data-parent-id="bottom_nav" data-target={index == objects.length - 1 ? null : `nav_btn_${objects[index + 1].category}`} data-content={t(`tutorial.${object.category}`)} item xs={2} md={0.8} lg={0.5} key={index} className="d-flex justify-content-end align-items-center flex-column">
              <img src={object.image} style={props.index == index ? enlarge_icon : normal_icon} onClick={() => handleLink(object)} />
              <img src={object.word[i18n.language]["img"]} style={{ height: object.word[i18n.language]["height"] }} />
            </Grid>
          ))}
        </Grid>
      </Toolbar>
    </>
  );
}
