import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import styled from "@mui/material/styles/styled";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import LinkBar from "~/components/EndBar/LinkBar";
import HomeButton from "~/components/HomeButton";

import bg from "~/assets/images/components/EndBar/Background.svg";
import add from "~/assets/images/components/EndBar/AddIcon.svg";
import asset_bg from "~/assets/images/components/EndBar/AssetBg.svg";
import notification from "~/assets/images/components/EndBar/Notification.svg";
import REESIcon from "~/assets/images/components/General/ReesIcon.svg";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 7,
    top: 10,
    border: `2px solid #ffffff`,
    padding: "0 1px",
    zIndex: 2,
    backgroundColor: "red",
    color: "white",
    fontFamily: "system-ui!important"
  },
}));

class Endbar extends Component {
  constructor(props) {
    super(props);
  }

  routingFunction = (param) => {
    this.props.history.push("/puzzle/package");
  };

  componentDidMount() {
    if (!window.apiToken) {
      window.sessionStorage.setItem("wallet", JSON.stringify([]));
      window.sessionStorage.setItem("notification", JSON.stringify({count:0, updated_at:Date.now()}));
    }
  }

  render() {
    return (
      <AppBar
        id="bottom_nav"
        color="transparent"
        position="fixed"
        align="center"
        sx={{ top: "auto", bottom: 0 }}
        style={{
          visibility: this.props.hide ? "hidden" : "visible",
          height: this.props.hide ? "0" : null,
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center left",
          heigth: "15vh",
          zIndex: 998,
          boxShadow: "none",
        }}>
        {this.props.home && <HomeButton bottom="16vh" />}
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} align="center">
            <LinkBar index={this.props.index} path={this.props.path} />
            <div
              style={{
                backgroundColor: "#242634",
              }}>
              {window.apiToken && (
                <Toolbar variant="dense">
                  <Grid item xs={12} container>
                    {JSON.parse(window.sessionStorage.getItem("wallet"))
                      .filter((wallet) => wallet.nav)
                      .map((asset, index) => (
                        <Grid key={index} item xs={4} variant="caption" component="div" sx={{ flexGrow: 1, textAlign: "center" }}>
                          <div
                            style={{
                              backgroundImage: `url(${asset_bg})`,
                              backgroundRepeat: "no-repeat",
                              backgroundAttachment: "local",
                              backgroundSize: "cover",
                            }}>
                            <Grid container justifyContent="center" alignItems="center" className="button" style={{ color: "white" }}>
                              <img
                                src={asset.image}
                                style={{
                                  maxWidth: "40px",
                                  position: "relative",
                                  float: "left",
                                }}
                              />
                              <Typography style={{ float: "right" }}>
                                {asset.amount}
                                <img src={add} style={{ width: 20, marginLeft: 2 }} onClick={this.routingFunction} />
                              </Typography>
                            </Grid>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      bottom: "6vh",
                      right: "5vw",
                    }}>
                    <StyledBadge badgeContent={JSON.parse(window.sessionStorage.getItem("notification") ?? '{"count":0}').count}>
                      <Link to="/messages">
                        <img style={{ height: "3em" }} src={notification} />
                      </Link>
                    </StyledBadge>
                  </div>
                </Toolbar>
              )}
            </div>
          </Grid>
        </Grid>
      </AppBar>
    );
  }
}

export default withRouter(Endbar);
