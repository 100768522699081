import * as React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import PoweredBy from "~/assets/images/components/General/PoweredByPuzzleChase.svg";
import Popup from "reactjs-popup";

import Ricon from "~/assets/images/components/General/ReesIcon.svg";
import Twopuzzle from "~/assets/images/components/General/Twopuzzle.svg";
import PuzzleChase from "~/assets/images/components/General/PuzzleChase.svg";
import ContinueButton from "~/assets/images/components/Wallet/ContinueButton.svg";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

const contentStyle = {
  background: "#242634",
  width: "90%",
  border: "none",
  height: "auto",
  borderRadius: "5%",
};
export default (props) => {
  const { t, i18n } = useTranslation();
  return (
    <Popup open={props.open} modal lockScroll nested onClose={props.setOpen} {...{ contentStyle }}>
      {(close) => (
        <div className="bg-transparent modal modal-content border-0">
          <button className="close" onClick={close}></button>
          <Grid container>
            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
              <img src={Ricon} style={{ width: "15%", marginRight: "1.5%", paddingTop: "1.5%" }} />
              <Typography variant="h4" color="white">
                |
              </Typography>
              <img src={PuzzleChase} style={{ width: "15%", marginLeft: "1.5%" }} />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
              <img src={Twopuzzle} style={{ width: "30%" }} />
            </Grid>
            <Grid item xs={12}>
              <Swiper modules={[Navigation]} navigation>
                <SwiperSlide>
                  <Typography variant="h6" color="white" align="center">
                    {t("puzzle_purchase.tutorial.page_1_title")}
                  </Typography>
                  <Typography variant="body1" color="white" align="center">
                    {t("puzzle_purchase.tutorial.page_1_content")}
                  </Typography>
                </SwiperSlide>
                <SwiperSlide>
                  <Typography variant="h6" color="white" align="center">
                    {t("puzzle_purchase.tutorial.page_2_title")}
                  </Typography>
                  <Typography variant="body1" color="white" align="center">
                    {t("puzzle_purchase.tutorial.page_2_content")}
                  </Typography>
                </SwiperSlide>
                <SwiperSlide>
                  <Typography variant="h6" color="white" align="center">
                    {t("puzzle_purchase.tutorial.page_3_title")}
                  </Typography>
                  <Typography variant="body1" color="white" align="center">
                    {t("puzzle_purchase.tutorial.page_3_content")}
                  </Typography>
                </SwiperSlide>
              </Swiper>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
              <img src={PoweredBy} style={{ width: "30vw", maxHeight: "10vh" }} />
            </Grid>
          </Grid>
        </div>
      )}
    </Popup>
  );
};
