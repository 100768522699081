import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { Link } from "react-router-dom";
import Notification from "~/assets/images/components/Messages/Bell.svg";
import Discord from "~/assets/images/components/Messages/Discord.svg";
import Whatsapp from "~/assets/images/components/Messages/Whatsapp.svg";

class MessageCenter extends Component {
  constructor(props) {
    super(props);
    this.handleLink = this.handleLink.bind(this);
  }

  handleLink(e) {
    if (e.substr(0,2) == "//") {
      window.top.location.href = e;
    } else {
      this.props.history.push(e);
    }
  };
  render() {
    const data = [
      { id: 0, icon: Notification, title: this.props.i18n.t("message_center.notification"), detail: this.props.i18n.t("message_center.notification_desc"), link: "/notifications" },
      // { id: 1, icon: Whatsapp, title: this.props.i18n.t("message_center.live_chat"), detail: this.props.i18n.t("message_center.live_chat_desc"), link: "//chat.whatsapp.com/DmSLLdOftqNJ4vIJxFurI0" },
      { id: 2, icon: Discord, title: this.props.i18n.t("message_center.community"), detail: this.props.i18n.t("message_center.community_desc"), link: "//discord.gg/Jajajg3aEx" },
    ];
    return (
      <List>
        {data.map((message, index) => {
          return (
            <ListItem key={index} button onClick={() => this.handleLink(message.link)} style={{ height: "20vh", backgroundColor: index % 2 == 0 ? "#242634" : "#35394C" }}>
              <ListItemAvatar>
                <img src={message.icon} width="50%" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h6" style={{ color: "#FFF" }}>
                    {message.title}
                  </Typography>
                }
                secondary={
                  <Typography variant="caption" style={{ color: "#FFF" }}>
                    {message.detail}
                  </Typography>
                }
              />
            </ListItem>
          );
        })}
      </List>
    );
  }
}

export default withTranslation()(MessageCenter);