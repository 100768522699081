// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../images/components/Popup/ClosePopup.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popup-content{background:#242634;height:50%;margin:auto;padding:5px;width:98%;z-index:4}.modal>.content{width:100%}.modal>.close{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:130% 130%;border:none;border-radius:50%;display:block;font-size:24px;height:6vh;position:absolute;right:-10px;top:-10px;width:6vh}", "",{"version":3,"sources":["webpack://./resources/react_front/src/assets/styles/Popup.css"],"names":[],"mappings":"AAAA,eAEE,kBAAmB,CAEnB,UAAW,CAHX,WAAY,CAIZ,WAAY,CAFZ,SAAU,CAGV,SACF,CAEA,gBACE,UACF,CAEA,cAUE,kDAA0D,CAC1D,yBAA0B,CAJ1B,WAAY,CAEZ,iBAAkB,CAPlB,aAAc,CAMd,cAAe,CALf,UAAW,CAFX,iBAAkB,CAIlB,WAAY,CACZ,SAAU,CAFV,SAQF","sourcesContent":[".popup-content {\n  margin: auto;\n  background: #242634;\n  width: 98%;\n  height: 50%;\n  padding: 5px;\n  z-index: 4;\n}\n\n.modal>.content {\n  width: 100%;\n}\n\n.modal>.close {\n  position: absolute;\n  display: block;\n  height: 6vh;\n  width: 6vh;\n  right: -10px;\n  top: -10px;\n  border: none;\n  font-size: 24px;\n  border-radius: 50%;\n  background: url(../images/components/Popup/ClosePopup.svg);\n  background-size: 130% 130%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
