// Import Plugin
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import HamburgerMenu from "./HamburgerMenu";
import back from "~/assets/images/components/General/BackNoShadow.svg";
import Grid from '@mui/material/Grid'

const BackButton = () => {
  const history = useHistory();
  if (history.location.pathname != "/index") {
    return (
      <img
        onClick={history.goBack}
        src={back}
        style={{
          maxHeight: "48px",
          marginLeft: "10px"
        }}
      />
    );
  } else {
    return null;
  }
};

const Content = (props) => {
  switch (props.content) {
    case "User":
      return (
        <Grid container>
          <Grid item xs="auto">
            {window.sessionStorage.getItem("profile_pic") && (
              <Link to="/profile/avatar" style={{ textDecoration: "none", height: "48px", marginLeft: "10px" }}>
                <img src={window.sessionStorage.getItem("profile_pic")} style={{ height: "48px", width: "48px" }} />
              </Link>
            )}
          </Grid>
          <Grid item xs display="flex" alignItems="center">
            <Link to="/profile" style={{ marginLeft: "1.5vw", textDecoration: "none", color: "white" }}>
              <Typography variant="h6">{window.sessionStorage.getItem("nickname") ?? "Guest"}</Typography>
            </Link>
          </Grid>
        </Grid>
      );
    default:
      return (
        <Typography variant="body2" component="div" sx={{ flexGrow: 1, textAlign: "center" }}>
          {props.content}
        </Typography>
      );
  }
}

class NavigationBar extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    if (!window.apiToken) {
      window.sessionStorage.removeItem("profile_pic");
      window.sessionStorage.removeItem("nickname");
      window.sessionStorage.removeItem("email");
      this.setState({ is_loaded: true });
    }
  }

  render() {
    // if (!this.state.is_loaded) {return (<></>)}
    return (
      <AppBar style={{ backgroundColor: "#242634", visibility: this.props.hide ? "hidden" : "visible", height: this.props.hide ? "0" : null }} position="fixed">
        <Toolbar style={{ padding: "5px 0px 5px 0px", justifyContent: "space-between" }} variant="dense">
          <BackButton />
          <Content content={this.props.content ?? "User"} />
          <HamburgerMenu />
        </Toolbar>
      </AppBar>
    );
  }
}

export default NavigationBar;
