import React, { Component } from "react";
import Popup from "reactjs-popup";
import { RWebShare } from "react-web-share";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Code from "~/assets/images/components/Dummy/QRCode.svg";

const contentStyle = {
  marginTop: "20%",
  background: "#242634",
  width: "90%",
  height: "60%",
  borderRadius: "5%",
};

export default (props) => {
  return (
    <Popup open={props.open} onClose={props.setOpen} modal lockScroll nested {...{ contentStyle }}>
      {(close) => (
        <div className="bg-transparent modal modal-content border-0">
          <button className="close" onClick={close}></button>
          <Grid item xs={12} marginTop="0vh" height="100%" container justifyContent="center" alignItems="center">
            <Typography variant="h6" component="div" color="primary" textAlign="center">
              Pay Now!
            </Typography>
            <img src={Code} width="100%" style={{ background: "aliceblue" }}/>
          </Grid>
        </div>
      )}
    </Popup>
  )
}
