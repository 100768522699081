import React from 'react'
import { Link } from "react-router-dom"
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Import Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default (props) => {
  return (
    <div>
      <div className="carousel-wrapper">
        <Swiper
          id={"TopSlider"}
          modules={[EffectCoverflow, Pagination, Navigation]}
          pagination={{ type: "bullets", el: ".swiper-pagination" }}
          loop={true}
          navigation={true}
          centeredSlides
          slidesPerView={1.2}
          spaceBetween={30}
          grabCursor={true}
          autoplay={{
            delay: 3000,
          }}
          breakpoints={{
            576: {
              slidesPerView: 1.2,
            },
            768: {
              slidesPerView: 2.5,
            },
            992: {
              slidesPerView: 3.5,
            },
          }}
          className="mySwiper">
          {props.data.map((content, i) => (
            <SwiperSlide key={i}>
              <a target="_parent" href={content.link}>
                <img style={{ width: "100%" }} className="swiper-lazy" sizes="(max-width: 300px) 300px, (max-width: 600px) 600px, (max-width: 900px) 900px, (max-width: 1200px) 1200px" src={content.banner_url} />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
