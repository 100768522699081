import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Fail from "~/assets/images/components/Purchase/Fail.svg";

class PaymentFail extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Grid container direction="row" justifyContent="center" alignContent="center" height="80vh">
        <Grid item xs={10} md={8} lg={6.2} display="flex" flexDirection="column" justifyContent="space-around" alignItems="center">
          <Typography variant="h6" color="white" textAlign="center">
            {this.props.i18n.t("purchase_fail.title")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={6.2} display="flex" justifyContent="center" alignItems="center">
          <img src={Fail} width="50%" />
        </Grid>
        <Grid item xs={6} md={8} lg={6.2} display="flex" justifyContent="center" alignItems="center">
          <Button variant="outlined" component={Link} to={window.apiToken ? "/puzzle/package" : "/index"} style={{ width: "100%", backgroundColor: "#5C2D80", color: "white", borderColor: "white" }}>
            {window.apiToken ? this.props.i18n.t("purchase_fail.return") : this.props.i18n.t("purchase_fail.login")}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(PaymentFail);
