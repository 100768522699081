import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import "reactjs-popup/dist/index.css";
import "~/assets/styles/Popup.css";
import Typography from "@mui/material/Typography";
import FreeShareCht from "~/assets/images/components/GamePayout/FreeShareCht.svg";
import FreeShareEng from "~/assets/images/components/GamePayout/FreeShareEng.svg";
import ChallengeAgainCht from "~/assets/images/components/GamePayout/ChallengeAgainCht.svg";
import ChallengeAgainEng from "~/assets/images/components/GamePayout/ChallengeAgainEng.svg";
// import PlayAgain from "~/assets/images/components/GamePayout/PlayAgain.svg";
// import FreeShare from "~/assets/images/components/GamePayout/FreeShare.svg";
import Grand from "~/assets/images/components/GamePayout/Grand.svg";
import Epic from "~/assets/images/components/GamePayout/Epic.svg";
import Hero from "~/assets/images/components/GamePayout/Hero.svg";
import KeepUp from "~/assets/images/components/GamePayout/KeepUp.svg";
import Bonus from "~/assets/images/components/GamePayout/Bonus.svg";
const background = {1:Epic, 2:KeepUp, 3:Hero, 4:Bonus, 5:Grand};
const FreeShare = { cht: FreeShareCht, eng: FreeShareEng };
const ChallengeAgain = { cht: ChallengeAgainCht, eng: ChallengeAgainEng };

class SPayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
      result: {
        image: null,
        reward_icon: null,
        reward_qty: 0,
        note: null,
        game_id: null,
      },
    };
  }

  componentDidMount() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios
      .get(`/api/game/s/result/${this.props.match.params.record_id}`)
      .then((response) => {
        document.getElementById("loading_curtain").classList.add("d-none");
        this.setState({
          is_loaded: true,
          result: response.data,
        });
      });
  }

  render() {
    if (!this.state.is_loaded) {
      return <></>;
    } else {
      return (
        <div className="d-flex align-items-center justify-content-between flex-column" style={{ minHeight: "65vh", backgroundImage: `url(${background[this.state.result.rank]}), url(${this.state.result.image})`, backgroundPosition: "top center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}>
          <div style={{ paddingTop: "15vh" }}>
            <div className="d-flex align-items-center justify-content-center">
              <img src={this.state.result.reward_icon} style={{ maxHeight: "3em" }} />
              <span className="h2 text-center text-white" style={{ overflowWrap: "break-word" }}>
                ×{this.state.result.reward_qty}
              </span>
            </div>
            <p className="h4 text-center text-white" style={{ overflowWrap: "break-word" }}>
              {this.state.result[`note_${this.props.i18n.language}`]}
            </p>
            <p className="text-white">
              {this.props.i18n.t("s_game.dont_miss_out")}
              <br />
              {this.props.i18n.t("s_game.try_again_for_double_bonus")}
            </p>
          </div>
          <div className="row w-100 justify-content-center">
            <div className="col-6 d-flex align-items-center justify-content-center">
              <Link to={`/lobby/${this.state.result.game_id}`}>
                <img src={ChallengeAgain[this.props.i18n.language]} className="img-fluid" />
              </Link>
            </div>
            {/* <div className="col-6 d-flex align-items-center justify-content-center">
              <img src={FreeShare} style={{ width: "95%" }} />
            </div> */}
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(SPayout);
