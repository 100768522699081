import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const formatter = new Intl.DateTimeFormat("en", { month: "short" });
class TransactionHistory extends Component {
  constructor() {
    super();
    this.state = {
      records: {},
      total_records: 0,
      is_fetching: false,
      has_mount: false,
    };
  }

  componentDidMount() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.addEventListener("scroll", this.handleScroll);
    this.fetch();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 && this.state.total_records > Object.keys(this.state.records).length && !this.state.is_fetching) {
      this.fetch(Object.keys(this.state.records).length);
    }
  };

  fetch = (start = "") => {
    start = start == "" ? "" : `/${start}`;
    this.setState({ is_fetching: true });
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios.get(`/api/transaction${start}`).then((response) => {
      document.getElementById("loading_curtain").classList.add("d-none");
      this.setState((prevState) => {
        return {...prevState, ...{
          records: Object.assign(prevState.records, response.data.transactions),
        }};
      });
      this.setState({
        total_records: response.data.total_transactions,
        is_fetching: false,
        has_mount: true,
      });
    });
  };

  render() {
    var i = 1;
    if (!this.state.has_mount) {
      return  (
        <></>
      );
    }
    if (Object.keys(this.state.records).length == 0) {
      return (
        <List>
          <ListItem style={{ backgroundColor: "#242634" }}>
            <ListItemText
              primary={
                <Typography variant="body" style={{ color: "#FFF" }}>
                  You have no transaction record.
                </Typography>
              }
            />
          </ListItem>
        </List>
      );
    }

    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} lg={8}>
          <List onScroll={this.handleScroll}>
            {Object.keys(this.state.records)
              .sort()
              .reverse()
              .map((index) => {
                i++;
                var record = this.state.records[index];
                var full_date = new Date(parseInt(record.time));
                switch (this.props.i18n.language) {
                  case "cht":
                    var month = full_date.getMonth() + 1;
                    break;
                  case "eng":
                  default:
                    var month = formatter.format(full_date);
                    break;
                }
                var year = full_date.getFullYear();
                var date = full_date.getDate();
                let itemProps = { secondaryAction: <></> };
                if (record.receipt) {
                  itemProps.component = Link;
                  itemProps.to = `receipt/${record.receipt}`;
                  itemProps.secondaryAction = (
                    <IconButton edge="end" style={{ textDecoration: "none", color: "#FFF" }}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  );
                }
                if (record.link) {
                  itemProps.component = "a";
                  itemProps.href = record.link;
                  itemProps.target = "_blank";
                  itemProps.secondaryAction = (
                    <IconButton edge="end" style={{ textDecoration: "none", color: "#FFF" }}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  );
                }
                return (
                  <ListItem key={i} style={{ backgroundColor: i % 2 ? "#242634" : "#35394C" }} {...itemProps}>
                    <ListItemAvatar>
                      <img src={record.logo ?? null} style={{ width: "3em" }} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="h6" style={{ color: record.quantity > 0 ? "#FF3C77" : "#8FABBE" }}>
                          {record[`desc_${this.props.i18n.language}`]}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="subtitle2" component="p" className="text-white-50" sx={{ textDecoration: "none", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "40vw" }}>
                          {this.props.i18n.t("transaction_history.date", { year: year, month: month, date: date })}
                        </Typography>
                      }
                    />
                    <ListItemText
                      primary={
                        <Typography variant="h6" style={{ textDecoration: "none", color: "#FFF", textAlign: "right" }}>
                          {record.quantity > 0 ? "+" : ""}
                          {record.quantity}
                          <img src={record.asset_logo} style={{ marginLeft: "0.25em", maxHeight: "1.5em" }} />
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              })}
          </List>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(TransactionHistory);
