import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

class MultigameChoose extends Component {
  constructor() {
    super();
    this.state = {data: []};
    this.handleLink = this.handleLink.bind(this);
  }

  handleLink(link) {
    window.localStorage.setItem("game_slider_position", link);
    window.Sound_NewSuccess.play();
    this.props.history.push(`/lobby/${link}`);
  }

  componentDidMount() {
    window.axios
      .get(`/api/game/all`)
      .then((response) => {
        this.setState({
          data: response.data,
        });
      });
  }
  render() {
    return (
      <Grid container justifyContent="center" style={{ paddingTop: "2vh" }} color="white">
        {this.state.data.map((game) => (
          <Grid item xs={4} sm={3} lg={2} key={game.id}>
            <div style={{ textAlign: "center", paddingTop: "2vmin" }}>
              <img src={game.src} onClick={() => this.handleLink(game.id)} width="95%" />
              <Typography variant="caption" noWrap>
                {game[`name_${this.props.i18n.language}`]} <br /> {game.playing} {this.props.i18n.t("main.playing_now")}
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default withRouter(withTranslation()(MultigameChoose));
