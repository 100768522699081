import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";

class MissionMain extends Component {
  constructor(props) {
    super(props);
    if (!window.apiToken) {
      this.props.history.push("/login");
    }
    this.state = {
      has_mount: false,
    };
  }

  componentDidMount() {
    if (!window.sessionStorage.getItem("mission")) {
      this.updateMissionList();
    } else {
      var updated_at = JSON.parse(window.sessionStorage.getItem("mission")).updated_at;
      if (updated_at) {
        if (Date.now() - updated_at > 120000) {
          this.updateMissionList();
        } else {
          this.updateComplete();
        }
      } else {
        this.updateMissionList();
      }
    }
  }

  updateMissionList() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios.get(`/api/mission/list`).then((response) => {
      window.sessionStorage.setItem("mission", JSON.stringify({ ...{ data: response.data }, ...{ updated_at: Date.now() } }));
      this.updateComplete();
    });
  }

  updateComplete() {
    this.setState({ has_mount: true });
    document.getElementById("loading_curtain").classList.add("d-none");
  }

  GetReward(mission_id) {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.Sound_ShortSuccess.play();
    window.axios
      .post(`/api/mission/reward/${mission_id}`)
      .then((response) => {
        window.updateWallet();
        window.sessionStorage.setItem("wallet", JSON.stringify(response.data));
        var missions = JSON.parse(window.sessionStorage.getItem("mission"));
        var target_mission = missions.data.filter((mission) => mission.id == mission_id)[0];
        target_mission.remain_rewards--;
        var new_missions = missions.data.filter((mission) => mission.id != mission_id);
        new_missions.push(target_mission);
        new_missions.sort((a, b) => a.id - b.id);
        window.sessionStorage.setItem("mission", JSON.stringify({ ...{ data: new_missions }, ...{ updated_at: Date.now() } }));
        document.getElementById("loading_curtain").classList.add("d-none");
        window.Sound_TwinkleAlert.play();
        toast.success(this.props.i18n.t("mission.success"), {
          containerId: "Toast_color_flip",
          autoClose: 3000,
        });
      });
  }

  render() {
    if (!this.state.has_mount) {
      return (
        <List>
          <ListItem style={{ backgroundColor: "#242634" }}>
            <ListItemText
              primary={
                <Typography variant="body" style={{ color: "#FFF" }}>
                  {this.props.i18n.t("mission.loading")}...
                </Typography>
              }
            />
          </ListItem>
        </List>
      );
    } else {
      return (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} lg={8} padding="1vh 0px" display="flex" justifyContent="center" bgcolor="#8e8b91">
            <Typography variant="subtitle1" align="center">
              {this.props.i18n.t("mission.header")}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={8}>
            <List style={{ paddingTop: "0px", paddingBottom: "0px" }}>
              {Object.values(JSON.parse(window.sessionStorage.getItem("mission") ?? '{"data":null}').data).map((mission, i) => {
                const btn_content = (
                  <>
                    <ListItemIcon>{mission.remain_rewards > 0 ? <CheckBoxOutlineBlankOutlinedIcon fontSize="large" sx={{ color: "white" }} /> : <CheckBoxOutlinedIcon fontSize="large" sx={{ color: "white" }} />}</ListItemIcon>
                    <ListItemText primary={mission[`title_${this.props.i18n.language}`]} />
                    <Grid container style={{ width: "min-content" }}>
                      <Grid item xs={12}>
                        <Button variant="outlined" style={{ color: "white", borderColor: "white", minWidth: "100px", maxWidth: "100px" }}>
                          {mission.reward_quantity}
                          <img src={mission.reward_type} style={{ height: "1em" }} />
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="white" align="center">
                          {this.props.i18n.t("mission.left", { number: mission.remain_rewards })}
                          {/* {this.props.i18n.t("mission.left", {number: `${mission.remain_rewards}/${mission.total_rewards}`})} */}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                );
                return (
                  <React.Fragment key={i}>
                    {mission.direct ? (
                      <ListItemButton onClick={() => this.GetReward(mission.id)} style={{ color: "white", backgroundColor: i % 2 == 0 ? "#242634" : "#35394C", textDecoration: "none" }}>
                        {btn_content}
                      </ListItemButton>
                    ) : (
                      <ListItemButton component={Link} to={mission.reward_detail} style={{ color: "white", backgroundColor: i % 2 == 0 ? "#242634" : "#35394C", textDecoration: "none" }}>
                        {btn_content}
                      </ListItemButton>
                    )}
                  </React.Fragment>
                );
              })}
            </List>
          </Grid>
        </Grid>
      );
    }
  }
}

export default withTranslation()(MissionMain);
