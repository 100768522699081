import React from "react";
import { useTranslation } from "react-i18next";
import { useCache } from "react-use-cache";
import Popup from "reactjs-popup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PoweredBy from "~/assets/images/components/General/PoweredByPuzzleChase.svg";

const contentStyle = {
  background: "#242634",
  width: "90%",
  height: "auto",
  border: "none",
  borderRadius: "10px",
};
export default (props) => {
  const { t, i18n } = useTranslation();
  const { isFetching, data: data } = useCache(() => window.axios.get(`/api/video/turotial`), `video_turotial`);
  if (isFetching) {
    return <></>;
  }
  return (
    <Popup open={props.open} modal lockScroll nested onClose={props.setOpen} {...{ contentStyle }}>
      {(close) => (
        <div className="bg-transparent modal modal-content border-0">
          <button className="close" onClick={close}></button>
          <Grid item xs={12}>
            <Typography variant="h6" component="div" color="white" textAlign="center">
              {t("game_help.how_to_play")}
            </Typography>
            <Typography variant="caption" component="div" color="white" textAlign="center">
              {data.data[`name_${i18n.language}`]}
            </Typography>
            <Grid item xs={12}>
              <div className="video-responsive">
                <iframe src={`https://www.youtube.com/embed/${data.data[`embed_${i18n.language}`]}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Embedded youtube" />
              </div>
            </Grid>
            <div style={{ textAlign: "center" }}>
              <img src={PoweredBy} style={{ width: "30vw", maxHeight: "12vh" }} />
            </div>
          </Grid>
        </div>
      )}
    </Popup>
  );
};
