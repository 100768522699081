import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { useStopwatch } from "react-timer-hook";
import MobileDetect from "mobile-detect";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";

import GameDetail from "~/components/Game/GameDetail";

import Forward from "~/assets/images/components/General/Forward.svg";
import Vs from "~/assets/images/components/GameV/Vs.svg";
import BgBlack from "~/assets/images/components/GameV/BgBlack.svg";
import Bgpink from "~/assets/images/components/GameV/BgPink.svg";
import B1V1tara from "~/assets/sounds/B1V1tara.mp3";
const Sound_Waiting = new Audio(B1V1tara);

// Import Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, EffectFade } from "swiper";

const md = new MobileDetect(navigator.userAgent);
var StartStopwatch = null;
var StopStopwatch = null;

function MyStopwatch() {
  const { seconds, minutes, start, pause } = useStopwatch({ autoStart: false });
  StartStopwatch = start;
  StopStopwatch = pause;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}>
      <div style={{ fontSize: "40px", color: "#FFFFFF" }}>
        <span>{("00" + minutes).slice(-2)}</span>:<span>{("00" + seconds).slice(-2)}</span>
      </div>
    </div>
  );
}

function GameButtonContent(props) {
  switch (props.status) {
    case "no":
      return (
        <>
          <Typography variant="body1" color="white" paddingTop="0.5vh">
            {props.i18n.t("v_game.entry_fee")}
          </Typography>
          <Typography variant="h6" color="white">
            <span>{props.entryFee}</span>
            <img src={props.costIcon} style={{width:"1.25rem"}} />
          </Typography>
        </>
      );
    case "waiting":
      return (
        <Typography variant="h4" color="white" paddingTop="0.5vh">
          <MyStopwatch />
        </Typography>
      );
    case "yes":
      return (
        <Typography variant="h4" color="white" paddingTop="0.5vh">
          {props.i18n.t("v_game.start")}
        </Typography>
      );
  }
}

class GameVWaitingRoom extends Component {
  constructor(props) {
    super(props);
    if (!window.apiToken) {
      props.history.push("/login");
    }
    document.getElementById("loading_curtain").classList.remove("d-none");
    this.state = {
      player1: {
        profile_pic: window.sessionStorage.getItem("profile_pic"),
        nickname: window.sessionStorage.getItem("nickname"),
      },
      player2: [{ src: null, name: null }],
      player2_status: "no",
      data: {
        id          : null,
        entryFee    : null,
        MaxPrize    : null,
        reward_icon : null,
        cost_icon   : null,
        MaxPrizeName: null,
        DetailLink  : null,
      },
      gameURL: null,
    };
    Sound_Waiting.load();
    this.swiper = React.createRef();
    // This binding is necessary to make `this` work in the callback
    this.joinGame = this.joinGame.bind(this);
    this.getPlayer2 = this.getPlayer2.bind(this);
    this.sound_fadeout = this.sound_fadeout.bind(this);
  }

  async componentDidMount() {
    const [costResponse, existResponse] = await Promise.all([
      window.axios.get(`/api/game/v/cost/${this.props.match.params.id}/${this.props.match.params.entryid}`),
      window.axios.get(`/api/game/v/exist/${this.props.match.params.id}/${this.props.match.params.entryid}`)
    ]);
    if (existResponse.data.status) {
      if (existResponse.data.parameters.token) {
        this.updatePlayer2();
        this.getPlayer2(existResponse.data.parameters.token);
      } else {
        this.setState({
          player2: existResponse.data.parameters,
          player2_status: "yes",
          gameURL: ["iOS", "iPadOS"].includes(md.os()) ? existResponse.data.ios_url : existResponse.data.android_url,
        });
      }
    }
    this.setState({
      data: costResponse.data,
    });
    this.swiper.current.swiper.autoplay.stop();
    document.getElementById("loading_curtain").classList.add("d-none");
  }

  joinGame() {
    window.Sound_Success.play();
    switch (this.state.player2_status) {
      case "no":
        document.getElementById("loading_curtain").classList.remove("d-none");
        window.axios
          .post(`/api/game/v/join`, {
            c: this.props.match.params.entryid,
            id: this.props.match.params.id,
          })
          .then((response) => {
            document.getElementById("loading_curtain").classList.add("d-none");
            if (response.data.status) {
              this.swiper.current.swiper.autoplay.stop();
              this.setState({
                player2: response.data.parameters,
                player2_status: "yes",
                gameURL: ["iOS", "iPadOS"].includes(md.os()) ? response.data.ios_url : response.data.android_url,
              });
            } else {
              Sound_Waiting.play();
              this.setState({
                player2_status: "waiting",
              });
              this.updatePlayer2();
              this.getPlayer2(response.data.parameters.token);
            }
          });
        break;
      case "waiting":
        break;
      case "yes":
        window.location.href = this.state.gameURL;
        break;
    }
  }

  updatePlayer2() {
    StartStopwatch();
    this.swiper.current.swiper.autoplay.start();
    window.axios
      .get(`/api/game/v/player_two/list`)
      .then((response) => {
        this.setState({
          player2: response.data,
        });
      });
  }

  getPlayer2(token) {
    window.axios
      .get(`/api/game/v/check/${token}`, {cache: {maxAge: 1000}})
      .then((response) => {
        if (response.data) {
          StopStopwatch();
          this.sound_fadeout();
          this.setState({
            player2: response.data.parameters,
            player2_status: "yes",
            gameURL: ["iOS", "iPadOS"].includes(md.os()) ? response.data.ios_url : response.data.android_url,
          });
        } else {
          setTimeout(this.getPlayer2, 3000, token);
        }
      });
  }

  sound_fadeout(){
    const fadeAudio = setInterval(() => {
      if (Sound_Waiting.volume !== 0) {
        Sound_Waiting.volume -= 0.1;
      }
      if (Sound_Waiting.volume < 0.003) {
        Sound_Waiting.pause();
        clearInterval(fadeAudio);
      }
    }, 50);
  }
  render() {
    return (
      <>
        <GameDetail gameId={this.props.match.params.id} modeId="v" />
        <Grid container direction="row" display="flex" alignItems="center" justifyContent="center">
          <Grid
            item
            xs={11.8}
            md={9}
            lg={6}
            container
            justifyContent="center"
            direction="row"
            alignItems="center"
            style={{
              height: "40vh",
              marginTop: "2vh",
              textAlign: "center",
              backgroundImage: `url(${BgBlack})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              border: "6px outset #ffffff",
              borderRadius: "10px 10px 10px 10px",
            }}>
            <img
              src={Vs}
              style={{
                maxHeight: "4rem",
                width: "100%",
                position: "absolute",
              }}
            />
            <Grid item height="50%"></Grid>
            <Grid item xs={12} height="50%" justifyContent="center" alignItems="center" display="flex" textAlign="center">
              <Swiper
                ref={this.swiper}
                modules={[Autoplay, EffectFade]}
                allowTouchMove={false}
                effect={"fade"}
                fadeEffect={{ crossFade: true }}
                autoplay={{
                  delay: 400,
                  disableOnInteraction: false,
                  waitForTransition: false,
                }}
                style={{ width: "100%", height: "100%" }}
                className="mySwiper">
                {this.state.player2.map((data, index) => {
                  return (
                    <SwiperSlide key={index} style={{ width: "100%", height: "100%" }}>
                      <Grid container style={{ height: "100%", paddingTop: "5vh" }}>
                        <Grid item xs={7} display="flex" justifyContent="end" alignItems="center">
                          <Typography variant="h6" component="div" color="white" textAlign="center">
                            {data.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={5} display="flex" justifyContent="start" alignItems="center" style={{ height: "100%", paddingLeft: "0.5em" }}>
                          <img src={data.src} style={{ maxHeight: "25vw", height: "100%" }} />
                        </Grid>
                      </Grid>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Grid>
            {/* next half page */}
            <Grid item xs={12} container height="50%" justifyContent="center" alignItems="center" style={{ textAlign: "center" }}>
              <Grid
                item
                xs={5}
                container
                justifyContent="end"
                alignItems="center"
                style={{
                  textAlign: "center",
                  height: "100%",
                  paddingBottom: "5vh",
                  paddingRight: "0.5em",
                }}>
                <img src={this.state.player1.profile_pic} style={{ maxHeight: "25vw", height: "100%" }} />
              </Grid>
              <Grid item xs={7} container justifyContent="start" alignItems="center" style={{ textAlign: "center", height: "100%" }}>
                <Typography variant="h6" component="div" color="white" textAlign="center" style={{ paddingBottom: "4vh" }}>
                  {this.state.player1.nickname}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" display="flex" justifyContent="end">
          <Grid
            item
            xs={5}
            height="10vh"
            position="relative"
            onClick={this.joinGame}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              backgroundImage: `url(${Bgpink})`,
              backgroundSize: "100% 110%",
              backgroundAttachment: "cover",
              textAlign: "center",
            }}>
            <GameButtonContent i18n={this.props.i18n} status={this.state.player2_status} entryFee={this.state.data.entryFee} costIcon={this.state.data.cost_icon} />
            <img
              src={Forward}
              width="30%"
              style={{
                position: "absolute",
                zIndex: 2,
                right: "0%",
                bottom: "-20%",
              }}
            />
          </Grid>
          <Grid item md={1.5} lg={3} height="9vh"></Grid>
        </Grid>
      </>
    );
  }
}

export default withTranslation()(GameVWaitingRoom);
