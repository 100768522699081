import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Fab from "@mui/material/Fab";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import HelpOutline from "@mui/icons-material/HelpOutline";

import PurchaseTutorialPopup from "~/components/Wallet/PurchaseTutorialPopup";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

class PuzzlePackage extends Component {
  constructor(props) {
    super(props);
    if (!window.apiToken) {
      this.props.history.push("/login");
    } else {
      document.getElementById("loading_curtain").classList.remove("d-none");
    }
    this.state = {
      count: 0,
      help: 0,
      wallet: [],
      purchase_asset: [],
      has_mount: false,
    };
  }

  componentDidMount() {
    window.axios.get(`/api/user/wallet`).then(response => {
      this.setState({ wallet: response.data });
      window.axios.get(`/api/purchase/asset`).then((response) => {
        document.getElementById("loading_curtain").classList.add("d-none");
        this.setState({ purchase_asset: response.data, has_mount: true });
      });
    });
  }

  render() {
    const PurchaseOption = (data) => {
      return data.package.map((item, index) => (
        <Link key={index} to={`/payment/method?l1=${data.id}&l2=${item.id}`} style={{ textDecoration: "none", color: "white" }}>
          <ListItem button style={{ backgroundColor: index % 2 == 0 ? "#8e8b91" : "#707070" }}>
            <ListItemText>
              <Grid container item xs={12} alignItems="center" justifyContent="center">
                <Grid item xs={2}>
                  <img src={item.image} width="100%" />
                </Grid>
                <Grid item xs>
                  <Typography>{item[`title_${this.props.i18n.language}`]}</Typography>
                  <Typography variant="caption">
                    {this.props.i18n.t("puzzle_purchase.package_detail", {
                      items: Object.values(item.details ?? { puzzle_type_eng: "", puzzle_type_cht: "" })
                        .map(detail => detail[this.props.i18n.language])
                        .join(", "),
                    })}
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <Typography>
                    {this.props.i18n.t("puzzle_purchase.hkd")}${item.cost}
                  </Typography>
                  <KeyboardArrowRightIcon />
                </Grid>
              </Grid>
            </ListItemText>
          </ListItem>
        </Link>
      ));
    };
    if (!this.state.has_mount) {
      return <></>;
    } else {
      return (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} lg={6.2} height="3vh" style={{ backgroundColor: "#8e8b91", textAlign: "center" }}>
            <Typography variant="body2" color="#ffffff" onClick={() => {this.setState((prevState) => ({ ...prevState, help: !prevState.help }));}}>
              {this.props.i18n.t("puzzle_purchase.hot_package_deal")}
              <HelpOutline />
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={6.2} style={{ borderTop: "3px solid white" }}>
            <List sx={{ padding: "0" }}>{PurchaseOption(this.state.purchase_asset[this.state.count])}</List>
          </Grid>
          <PurchaseTutorialPopup
            open={this.state.help}
            setOpen={() => {
              this.setState((prevState) => ({ ...prevState, help: !prevState.help }));
            }}
          />
        </Grid>
      );
    }
  }
}

export default withTranslation()(PuzzlePackage);
