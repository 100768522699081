// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../fonts/Fontfabric - UniNeueBold.otf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;margin:0}body::-webkit-scrollbar,html::-webkit-scrollbar{display:none}body ::-moz-scrollbar,html ::-moz-scrollbar{display:none}body,html{-ms-overflow-style:none;overflow:auto;scrollbar-color:transparent transparent}code{font-family:source-code-pro,Menlo,Monaco,Consolas,Courier New,monospace}@font-face{font-family:MyFont;src:local(\"MyFont\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"otf\")}#root{white-space:pre-line}", "",{"version":3,"sources":["webpack://./resources/react_front/src/assets/styles/index.css"],"names":[],"mappings":"AAAA,KAKE,kCAAmC,CACnC,iCAAkC,CALlC,QAMF,CAEA,gDAEE,YACF,CAEA,4CAEE,YACF,CAEA,UAGE,uBAAwB,CADxB,aAAc,CAEd,uCAEF,CAEA,KACE,uEACF,CAEA,WACE,kBAAqB,CACrB,yEAGF,CAEA,MACE,oBACF","sourcesContent":["body {\n  margin: 0;\n  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif; */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nhtml::-webkit-scrollbar,\nbody::-webkit-scrollbar {\n  display: none;\n}\n\nhtml ::-moz-scrollbar,\nbody ::-moz-scrollbar {\n  display: none;\n}\n\nhtml,\nbody {\n  overflow: auto;\n  -ms-overflow-style: none;\n  scrollbar-color: transparent transparent;\n  /*just hides the scrollbar for firefox */\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n@font-face {\n  font-family: 'MyFont';\n  src: local('MyFont'), url(../fonts/Fontfabric\\ -\\ UniNeueBold.otf) format('otf');\n  /* other formats include: 'woff2', 'truetype, 'opentype',\n                            'embedded-opentype', and 'svg' */\n}\n\n#root {\n  white-space: pre-line;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
