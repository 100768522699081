import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ShortcutIcon from "@mui/icons-material/Shortcut";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      total_messages: 0,
      is_fetching: false,
      has_mount: false,
    };
    this.handleLink = this.handleLink.bind(this);
  }

  componentDidMount() {
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.addEventListener("scroll", this.handleScroll);
    this.fetch_message();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 && this.state.total_messages > this.state.messages.length && !this.state.is_fetching) {
      this.fetch_message(this.state.messages.length);
    }
  };

  handleLink = (link) => {
    if (link.substr(0, 2) == "//" || link.substr(0, 8) == "https://" || link.substr(0, 7) == "http://") {
      window.top.location.href = link;
    } else if (link.substr(0, 2) == "*/") {
      window.location.assign(link.replace("*", ""));
    } else {
      this.props.history.push(link);
    }
  };

  fetch_message = (start = "") => {
    start = start == "" ? "" : `/${start}`;
    this.setState({ is_fetching: true });
    document.getElementById("loading_curtain").classList.remove("d-none");
    window.axios.get(`/api/noti${start}`).then((response) => {
      document.getElementById("loading_curtain").classList.add("d-none");
      this.setState({
        total_messages: response.data.total_messages,
        messages: this.state.messages.concat(response.data.messages),
        is_fetching: false,
        has_mount: true,
      });
    });
  };

  read_message = (id) => {
    document.getElementById("loading_curtain").classList.remove("d-none");
    return new Promise((resolve) => {
      window.axios.post(`/api/noti/read/${id}`).then((response) => {
        document.getElementById("loading_curtain").classList.add("d-none");
        var message_index = this.state.messages.findIndex((message) => message.id == response.data.id);
        if (message_index >= 0) {
          this.setState(({ messages }) => ({
            messages: [
              ...messages.slice(0, message_index),
              {
                ...messages[message_index],
                read: response.data.read,
              },
              ...messages.slice(message_index + 1),
            ],
          }));
          window.sessionStorage.setItem("notification", JSON.stringify({ count: JSON.parse(window.sessionStorage.getItem("notification")).count - 1, updated_at: Date.now() }));
          window.updateNotificationFromSession();
        }
        resolve();
      });
    });
  };

  render() {
    if (!this.state.has_mount) {
      return <></>;
    }
    if (this.state.messages.length == 0) {
      return (
        <List>
          <ListItem style={{ backgroundColor: "#242634" }}>
            <ListItemText
              primary={
                <Typography variant="body" style={{ color: "#FFF" }}>
                  You have no message.
                </Typography>
              }
            />
          </ListItem>
        </List>
      );
    }
    return (
      <Grid container spacing={0} display="flex" alignItems="center" justifyContent="center">
        <Grid item md={10} lg={6}>
          <List onScroll={this.handleScroll} style={{ color: "#FFF" }}>
            {this.state.messages.map((message, index) => {
              return (
                <ListItem
                  key={message.id}
                  button
                  style={{ backgroundColor: index % 2 == 0 ? "#242634" : "#35394C" }}
                  secondaryAction={
                    message.link ? (
                      <IconButton edge="end" aria-label="goto" style={{ color: "#FFF" }} onClick={() => {if (!message.read) {this.read_message(message.id).then(() => {this.handleLink(message.link)});} else {this.handleLink(message.link);};}}>
                        <ShortcutIcon />
                      </IconButton>
                    ) : (
                      <></>
                    )
                  }>
                  <ListItemButton
                    onClick={() => {
                      if (!message.read) {
                        this.read_message(message.id);
                      }
                    }}>
                    <ListItemAvatar>
                      <Badge color="secondary" variant="dot" invisible={message.read}>
                        <img src={message.icon ?? null} style={{ width: "3em" }} />
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="body" style={{ textDecoration: "none" }}>
                          <div dangerouslySetInnerHTML={{ __html: message.content[this.props.i18n.language] }} style={{ textDecoration: "none" }} />
                        </Typography>
                      }
                      secondary={<Typography variant="caption">{message.created_at}</Typography>}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(Notification);
