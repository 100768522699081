// Import plugin
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";

import GameDetail from "~/components/Game/GameDetail"
import ModeSlider from "~/components/Game/ModeSlider";

import Swal from "sweetalert2";

class Lobby extends Component {
  constructor(props) {
    super(props);
    if (!window.apiToken) {
      this.props.history.push("/login");
      this.state = { can_load: false };
    } else {
      document.getElementById("loading_curtain").classList.remove("d-none");
      this.state = { can_load: true };
    }
  }
  componentDidMount() {
    document.getElementById("loading_curtain").classList.add("d-none");
  }

  render() {
    if (this.state.can_load) {
      return (
        <Box sx={{ flexGrow: 1 }}>
          <Grid item xs={12}>
            <GameDetail gameId={this.props.match.params.id} />
          </Grid>
          <Grid item xs={12}>
            <ModeSlider gameId={this.props.match.params.id} />
          </Grid>
        </Box>
      );
    } else {
      return <></>;
    }
  }
}

export default withTranslation()(Lobby);
